import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import moment, { Moment } from 'moment';
@Directive({
    selector: '[crossDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: CrossDateValidatorDirective, multi: true }],
})
export class CrossDateValidatorDirective implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        const startDate: Moment = control.get('startDate')?.value;
        const endDate: Moment = moment(control.get('endDate')?.value);

        if (!startDate && !endDate) {
            return null;
        }

        if (
            !endDate.isSameOrAfter(startDate) &&
            endDate.isValid() &&
            startDate?.isValid() &&
            control.get('endDate')?.dirty
        ) {
            return {
                matDatepickerMin: true,
            };
        }

        return null;
    }
}
