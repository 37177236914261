import { ModelMixin } from '../core/base-generic.model';

export default class Video extends ModelMixin<Video>() {
    protected static _type = 'videos';
    protected static _resource = 'videos/videos';
    protected static _serializeAttributes = ['externalId', 'height', 'thumbnailUrl', 'title', 'url', 'width'];

    get externalId(): string {
        return this._attributes['externalId'];
    }

    get height(): number {
        return this._attributes['height'];
    }

    get thumbnailUrl(): string {
        return this._attributes['thumbnailUrl'];
    }

    get title(): string {
        return this._attributes['title'];
    }

    get url(): string {
        return this._attributes['url'];
    }

    set url(url: string) {
        this._attributes['url'] = url;
    }

    get width(): number {
        return this._attributes['width'];
    }
}
