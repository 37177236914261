import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { EmploymentTypes } from '@app/constants/employment-types';
import { Employee } from '@models/employee/employee.model';
import { Office } from '@models/company/office.model';
import { TerminationCodes } from '@app/constants/termination-codes';

@Component({
    selector: 'app-form-employees-create-terminated-job',
    templateUrl: './create-terminated-job.template.html',
})
export class CreateTerminatedJobForm extends BaseForm {
    @Input() employee: Employee;
    @Input() offices: Office[] = [];
    @Input() terminatedOn: string;
    @Output() terminatedOnChange = new EventEmitter();

    employmentTypes = EmploymentTypes;
    terminationCodes = TerminationCodes;

    setOffice(office: Office): void {
        this.employee.officeId = office.id;
        this.employee.office = office;
    }

    setTerminatedOn(date: string): void {
        this.terminatedOn = date;
        this.terminatedOnChange.emit(date);
    }
}
