import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-employees-default-welcome-message',
    templateUrl: './default-welcome-message.form.html',
})
export class DefaultWelcomeMessageForm extends BaseForm {
    @Input() defaultWelcomeMessage: string;
}
