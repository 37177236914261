import { Component, Input } from '@angular/core';
import { HireQuestion } from '@models/employee/hire-question.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-onboarding-question',
    templateUrl: './onboarding-question.template.html',
})
export class OnboardingQuestionForm extends BaseForm {
    @Input() hireQuestion: HireQuestion = new HireQuestion();
}
