import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Platform } from '@app/classes';
import { DocumentAssignment } from '@app/models/documents/document-assignment.model';
import { CompanySetting } from '@app/models/settings/company-setting.model';
import { Project } from '@app/models/time-tracking/project.model';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { TimeTrackingSettingsService } from '@app/modules/time-tracking/services/time-tracking-settings.service';
import { AuthService, NotifyService } from '@app/services';
import { RoleNameDisplayOptions } from '@app/types/translatables/role-display.options';
import { TimeOffv3WorkScheduleDisplayOptions } from '@app/types/translatables/time-off-v3-display.options';
import { BaseForm } from '@forms/base.form';
import { Account } from '@models/account/account.model';
import { Role } from '@models/account/role.model';
import { Employee } from '@models/employee/employee.model';
import { HireQuestion } from '@models/employee/hire-question.model';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';
import { OnboardingVideo } from '@models/employee/onboarding-video.model';
import { Salary } from '@models/employee/salary.model';
import { DataField } from '@models/settings/data-field.model';
import { WorkSchedule } from '@models/time-off-v3/works-schedule.model';
import Video from '@models/videos/video.model';
import VideoMetadata from '@videos/value-objects/video-metadata.vo';
import { SelectedPolicy } from '@forms/employees/components/onboarding-time-off-policies/onboarding-time-off-policies.form';
import { TimeOffPolicy } from '@app/models/time-off-v3/time-off-policy.model';
import { OnboardingOption } from '@employees/../../models/employee/onboarding-option.model';
import { FeatureService } from '@app/services/feature.service';

@Component({
    selector: 'app-employees-form-hire-review',
    templateUrl: './review.form.html',
    styleUrls: ['./review.style.scss'],
})
export class ReviewForm extends BaseForm implements OnInit {
    @Input() employee: Employee;
    @Input() account: Account;
    @Input() salary: Salary;
    @Input() documentAssignments: DocumentAssignment[] = [];
    @Input() customFields: DataField[] = [];
    @Input() onboardingReminders: OnboardingReminder[] = [];
    @Input() workSchedule: WorkSchedule = null;
    @Input() roles: Role[] = [];
    @Input() trainingPrograms: TrainingProgram[] = [];
    @Input() selectedBenefitLabel: string | null = null;
    @Input() defaultOnboardingVideo?: Video;
    @Input() onboardingVideo?: OnboardingVideo;
    @Input() addToTimeTracking = false;
    @Input() timeTrackingProjects: Project[] = [];
    @Input() addToTimeTrackingPayrollIntegration = false;
    @Input() timeOffPolicies: SelectedPolicy[] = [];

    isLoading = false;

    selfServeFeatureFlag = false;
    customWelcomeMessage = false;
    hasOnboardingQuestions = false;
    showTimeTracking = false;
    timeTrackingPayrollIntegrationOnForCompany = false;
    onboardingSettingsURL = '/employees/settings/onboarding';
    selectedTimeOffPolicies: TimeOffPolicy[] = [];

    roleDisplay = RoleNameDisplayOptions;
    workScheduleDisplay = TimeOffv3WorkScheduleDisplayOptions;

    constructor(
        private auth: AuthService,
        private notify: NotifyService,
        private timeTrackingSettingsService: TimeTrackingSettingsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        const [questions] = await HireQuestion.param('company', this.auth.company.id).all();

        if (questions.length) {
            this.hasOnboardingQuestions = true;
        }

        this.getCompanySettings();
        this.defaultWelcomeMessage();

        this.showTimeTracking = Boolean(
            this.auth.company?.modules.find((module) => module.name === Platform.modules.timeTracking)
        );
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['timeOffPolicies']) {
            this.setSelectedTimeOffPolicies();
        }
    }

    setEmployeeWelcomeMessage(include: boolean): void {
        if (!include) {
            this.employee.welcomeMessage = null;
        }
    }

    handleOnboardingVideoChange(metadata?: VideoMetadata): void {
        if (metadata) {
            this.onboardingVideo.video = new Video({
                url: metadata.url,
            });
        } else {
            this.onboardingVideo.video = null;
        }
    }

    private async getCompanySettings(): Promise<void> {
        this.isLoading = true;

        try {
            const [companySettings] = await CompanySetting.param('company', this.auth.company.id)
                .where('module', 'EmployeeManagement')
                .with('setting')
                .get();

            this.setCollectHiringQuestionsBasedOnSettings(companySettings);
            this.setCustomWelcomeMessageBasedOnSettings(companySettings);

            if (this.auth.isAssignedTimeTrackingAdmin()) {
                const settings = await this.timeTrackingSettingsService.get();
                this.timeTrackingPayrollIntegrationOnForCompany = settings.payrollIntegration ?? false;
            }
        } catch (err) {
            this.notify.error(err.message || 'forms.applicant-tracker.review.couldNotFetchCompanySettings');
        } finally {
            this.isLoading = false;
        }
    }

    private async defaultWelcomeMessage(): Promise<void> {
        // Code inspired by loadOnboardingOptions() found in
        // https://github.com/Humi-HR/interface/blob/master/apps/hr-angular/src/app/forms/setup-welcome/setup-welcome.form.ts
        OnboardingOption.param('company', this.auth.company.id)
            .all()
            .then(([onboardingOptions]) => {
                const defaultWelcomeMessage = onboardingOptions.length
                    ? onboardingOptions[0].defaultWelcomeMessage
                    : null;
                if (defaultWelcomeMessage) {
                    this.customWelcomeMessage = true;
                    this.employee.welcomeMessage = defaultWelcomeMessage;
                }
            });
    }

    private setCollectHiringQuestionsBasedOnSettings(companySettings: CompanySetting[]): void {
        const sendHiringQuestionnaireToNewHires = companySettings.find(
            (setting) => setting.setting.name === 'sendHiringQuestionnaireToNewHiresByDefault'
        );

        if (sendHiringQuestionnaireToNewHires?.value && this.hasOnboardingQuestions) {
            this.employee.collectHiringQuestions = true;
        }
    }

    private setCustomWelcomeMessageBasedOnSettings(companySettings: CompanySetting[]): void {
        const sendWelcomeMessageToNewHires = companySettings.find(
            (setting) => setting.setting.name === 'sendWelcomeMessageToNewHiresByDefault'
        );

        if (sendWelcomeMessageToNewHires?.value) {
            this.customWelcomeMessage = true;
        }
    }

    private setSelectedTimeOffPolicies(): void {
        this.selectedTimeOffPolicies = this.timeOffPolicies
            .map((sp) => sp.timeOffPolicy)
            .filter((sp): sp is TimeOffPolicy => Boolean(sp));
    }
}
