import { Component, Input } from '@angular/core';
import { Department } from '@models/company/department.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-department-form',
    templateUrl: './department.template.html',
})
export class DepartmentForm extends BaseForm {
    @Input() department: Department = new Department();
}
