import { Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CompanyAdditionalIncome } from '@app/models/payroll/company-additional-income.model';
import { EmployeeAdditionalIncome } from '@app/models/payroll/employee-additional-income.model';
import { NotifyService } from '@app/services';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-edit-additional-income',
    templateUrl: './edit-additional-income.template.html',
})
export class EditAdditionalIncomeForm extends BaseForm {
    @ViewChild('form') form: NgForm;
    @Input() modalContainer;
    @Input() pendingPayrollExists = false;
    @Input() companyAdditionalIncomes: CompanyAdditionalIncome[] = [];

    constructor(private notify: NotifyService) {
        super();
    }

    get noIncomeTypesLeft(): boolean {
        return !this.companyAdditionalIncomes.some(
            ({ employeeAdditionalIncomes }) => employeeAdditionalIncomes[0]?.active
        );
    }

    async onRemove(employeeAdditionalIncome: EmployeeAdditionalIncome): Promise<void> {
        try {
            employeeAdditionalIncome.amount = 0;
            employeeAdditionalIncome.active = false;
            if (this.noIncomeTypesLeft) {
                this.modalContainer.deny();
            }

            await employeeAdditionalIncome.save();
            this.notify.success('employees.jobAndPay.removedIncomeType');
        } catch (err) {
            employeeAdditionalIncome.revert();
            this.notify.error('employees.jobAndPay.couldNotRemoveIncomeType');
        }
    }
}
