import { BaseForm } from '@forms/base.form';
import { Component, Input } from '@angular/core';
import { makePostalCodeMask } from '@app/functions';
import { TextMask } from '@app/interfaces';
import { AbilityService } from '@app/services';
import { Address } from '@app/models/employee/address.model';

@Component({
    selector: 'app-form-address',
    templateUrl: './address.template.html',
})
export class AddressForm extends BaseForm {
    @Input() address: Address = new Address();
    postalCodeMask: TextMask = makePostalCodeMask();
    payrollEnabled = false;

    constructor(private abilities: AbilityService) {
        super();
        this.payrollEnabled = this.abilities.payroll();
    }
}
