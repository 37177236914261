import { Component, Input } from '@angular/core';
import { Account } from '@models/account/account.model';
import { Employee } from '@models/employee/employee.model';
import { BaseForm } from '@forms/base.form';
import { TextMask } from '@app/interfaces';
import { makePostalCodeMask } from '@app/functions';
import { Address } from '@app/models/employee/address.model';

@Component({
    selector: 'app-form-employees-create-terminated-personal',
    templateUrl: './create-terminated-personal.template.html',
})
export class CreateTerminatedPersonalForm extends BaseForm {
    @Input() account: Account;
    @Input() employee: Employee;
    @Input() address: Address;
    postalCodeMask: TextMask = makePostalCodeMask();
}
