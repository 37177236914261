import { Component, Input, ViewChild } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { PayrollEmployee } from '@app/models/payroll/payroll-employee.model';

@Component({
    selector: 'app-form-additional-tax',
    templateUrl: './additional-tax.template.html',
})
export class AdditionalTaxForm extends BaseForm {
    @ViewChild('form') form;
    @Input() payrollEmployee: PayrollEmployee;
}
