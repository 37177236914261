import { Component, Input } from '@angular/core';
import { Journey } from '@models/employee/journey.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-journey',
    templateUrl: './journey.template.html',
})
export class JourneyForm extends BaseForm {
    @Input() journey: Journey = new Journey();
}
