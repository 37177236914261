import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AudioRecorderComponent } from '@app/components';
import { SupportedAudioFormats } from '@app/components/app-audio-recorder/enums/audio-formats.enum';
import { AnalyticEvents, FeatureFlag } from '@app/enums';
import { GendersCollection } from '@app/interfaces';
import { PronounsCollection } from '@app/interfaces/pronouns.interface';
import { AnalyticService, AudioFileService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { TranslatableKey } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';
import { EmployeeNamePronunciation } from '@models/employee/employee-name-pronunciation.model';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-form-basic-information',
    templateUrl: './basic-information.form.html',
    styleUrls: ['./basic-information.style.scss'],
})
export class BasicInformationForm extends BaseForm {
    @ViewChild('audioRecorder') audioRecorder: AudioRecorderComponent;
    @Input() description: TranslatableKey;
    @Input() employee: Employee;
    @Output() addPendoTrackingForGenders: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() addPendoTrackingForPronouns: EventEmitter<boolean> = new EventEmitter<boolean>();

    shouldShowEmployeeNamePronunciation = false;
    isAudioRecording = false;
    audioBlob: Blob | null = null;
    supportedAudioFormat: SupportedAudioFormats | null = null;

    constructor(
        private featureService: FeatureService,
        private audioFileService: AudioFileService,
        private analyticService: AnalyticService
    ) {
        super();

        this.featureService.has(FeatureFlag.employeeNamePronunciation).then((shouldShow) => {
            this.shouldShowEmployeeNamePronunciation = shouldShow;
        });
    }

    onCancel(): void {
        if (this.shouldShowEmployeeNamePronunciation) {
            this.audioRecorder.onStop();
        }
    }

    beforeShow(): void {
        if (this.shouldShowEmployeeNamePronunciation && this.employee.employeeNamePronunciation) {
            this.audioRecorder.hasPersistedAudioFile = true;
            this.audioRecorder.loadAudio(this.employee.employeeNamePronunciation.file);
        }

        /*
         * On form reopen after submit, show no validation errors before next submit click.
         */
        if (this.form.submitted) {
            this.form.resetForm(this.form.value);
        }
    }

    async submit(): Promise<void> {
        super.submit();

        if (this.shouldShowEmployeeNamePronunciation) {
            await this.handleEmployeeNamePronunciationOnSubmit();
        }
    }

    setGenders(allGenders: GendersCollection): void {
        this.employee.genders = allGenders;
        this.addPendoTrackingForGenders.emit(true);
    }

    setPronouns(allPronouns: PronounsCollection): void {
        this.employee.pronouns = allPronouns;
        this.addPendoTrackingForPronouns.emit(true);
    }

    addPendoEventForAudioPlayed(): void {
        this.analyticService.trackEvent(AnalyticEvents.EmployeeNamePronunciationBasicInformationPlayed);
    }

    private async handleEmployeeNamePronunciationOnSubmit(): Promise<void> {
        /*
         * Delete EmployeeNamePronunciation
         */
        if (this.audioRecorder.shouldDeletePreviousRecording) {
            await this.employee.employeeNamePronunciation
                .param('company', this.employee.companyId)
                .param('employee', this.employee.id)
                .delete();
            this.analyticService.trackEvent(AnalyticEvents.EmployeeNamePronunciationBasicInformationDeleted);
        }

        /*
         * Save the EmployeeNamePronunciation
         */
        if (this.audioBlob !== null) {
            this.audioFileService.store(this.audioBlob, this.supportedAudioFormat).then((file) => {
                const pronunciation = new EmployeeNamePronunciation();
                pronunciation.file = file;
                pronunciation
                    .param('company', this.employee.companyId)
                    .param('employee', this.employee.id)
                    .create()
                    .then((newPronunciation) => {
                        this.employee.employeeNamePronunciation = newPronunciation;
                        this.analyticService.trackEvent(AnalyticEvents.NamePronunciationBasicInformationCreated);
                    });
            });
        }

        this.audioRecorder.reset();
    }
}
