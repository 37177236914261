import { Translatable } from '@app/types/translatable.type';

const TemplateSlotNamesDisplayOptions: { [key: string]: Translatable } = {
    Employee: 'document.slotNames.employee',
    'Primary Cosigner': 'document.slotNames.primaryCosigner',
    'Secondary Cosigner': 'document.slotNames.secondaryCosigner',
    'Tertiary Cosigner': 'document.slotNames.tertiaryCosigner',
};

export { TemplateSlotNamesDisplayOptions };
