import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DeprecatedConfirmationDialogComponent } from '@app/components';
import { NotifyService } from '@app/services';
import { TrainingProgramRecord } from '@models/training/training-program-record.model';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-training-record-delete',
    templateUrl: './delete-record.template.html',
})
export class DeleteRecordModal {
    @ViewChild('dialog') dialog: DeprecatedConfirmationDialogComponent;
    @Output() deleted: EventEmitter<null> = new EventEmitter<null>();

    record: TrainingProgramRecord;

    constructor(
        private notify: NotifyService,
        private translateService: TranslateService
    ) {}

    confirm(record: TrainingProgramRecord): void {
        this.record = record;
        this.setTitle();
        this.dialog.show().then((confirm) => {
            if (!confirm) {
                return;
            }

            this.record
                .delete()
                .then(() => {
                    this.deleted.emit();
                    this.notify.success('form.training-record.recordDeleted');
                })
                .catch(() => {
                    this.notify.error('form.training-record.couldNotDeleteRecord');
                });
        });
    }

    private setTitle(): void {
        this.dialog.header = this.translateService.instant('forms.training-record.deleteDialogTitle', {
            firstName: this.record.employee.firstName,
            trainingProgramName: this.record.trainingProgram.name,
        });
    }
}
