import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    BulkSelectCompanyDocumentsDialogComponent,
    DeprecatedModalComponent,
    DeprecatedModalFormComponent,
    PdfViewerComponent,
} from '@app/components';
import { AnalyticEvents, FeatureFlag } from '@app/enums';
import { CompanyDocument } from '@app/models/documents/company-document.model';
import { DocumentAssignment } from '@app/models/documents/document-assignment.model';
import { Employee } from '@app/models/employee/employee.model';
import { SlotAssignment } from '@app/modules/document/classes';
import { EmployeeHiringService } from '@app/modules/employees/services';
import { AnalyticService, FileHelperService, NotifyService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { TemplateSlotNamesDisplayOptions } from '@app/types/translatables/template-slot-names-display.options';
import { BaseForm } from '@forms/base.form';
import { OnboardingMultiSignerAssignmentForm } from './onboarding-multi-signer-assignment/onboarding-multi-signer-assignment.form';
import { HelloSignEmbedService } from '@app/services/hello-sign-embed.service';

@Component({
    selector: 'app-employees-form-hire-onboarding-documents',
    templateUrl: './onboarding-documents.form.html',
    styleUrls: ['./onboarding-documents.style.scss'],
    providers: [HelloSignEmbedService],
})
export class OnboardingDocumentsForm extends BaseForm {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('selectCompanyDocuments', { static: true }) selectDocuments: BulkSelectCompanyDocumentsDialogComponent;
    @ViewChild('pdfPreviewModal', { static: true }) pdfPreviewModal: DeprecatedModalComponent;
    @ViewChild('pdfViewer', { static: true }) pdfPreview: PdfViewerComponent;
    @ViewChild('multiSignerAssignmentModal', { static: true }) multiSignerAssignmentModal: DeprecatedModalFormComponent;
    @ViewChild('multiSignerAssignmentForm', { static: true })
    multiSignerAssignmentForm: OnboardingMultiSignerAssignmentForm;

    @Input() documentAssignments: DocumentAssignment[] = [];
    @Output() documentAssignmentsChange: EventEmitter<DocumentAssignment[]> = new EventEmitter<DocumentAssignment[]>();

    newEmployee: Employee;
    isLoading = false;

    allowMultiSignDocuments = false;

    // Used for hidden element to prevent next without complete slot assignments
    allDocumentAssignmentValid = null;
    hasAllDocumentAssigned = false;

    slotDisplayOptions = TemplateSlotNamesDisplayOptions;

    constructor(
        private fileHelper: FileHelperService,
        private hiringService: EmployeeHiringService,
        private featureService: FeatureService,
        private notify: NotifyService,
        private helloSign: HelloSignEmbedService,
        private analyticService: AnalyticService
    ) {
        super();
        this.setFeatureFlag();
    }

    async onDocumentPreview(documentAssignment: DocumentAssignment): Promise<void> {
        if (documentAssignment.isDocumentAcknowledgement()) {
            await this.pdfPreview.setSource(this.fileHelper.path(documentAssignment.companyDocument.fileId));
            this.pdfPreviewModal.show();
            return;
        }

        await this.editHelloSignTemplate(documentAssignment);
    }

    async onSelectDocuments(): Promise<void> {
        const currentCompanyDocuments = this.documentAssignments.map((d) => d.companyDocument);
        const selectedDocument = await this.selectDocuments.show(currentCompanyDocuments);

        if (selectedDocument) {
            this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddDocuments);
        }

        this.buildDocumentAssignments(selectedDocument);
        this.checkAndSetAllDocumentAssignment();
    }

    onDocumentFiltersAdded(): void {
        this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeDocumentFiltersAdded);
    }

    buildDocumentAssignments(documents: CompanyDocument[]): void {
        if (!documents) {
            return;
        }

        // we wrap each document in a DocumentAssignment to containerize the information
        this.documentAssignments = documents.map((document) => {
            // Find if current document was previously selected to be assigned
            const previouslySelected = this.documentAssignments.find(
                (documentAssignment: DocumentAssignment) => documentAssignment.companyDocument.id === document.id
            );

            // if this document was previously assigned, return the reference to the previously select document
            // this prevents overriding any changed values the user may have made before adding or removing documents to assign
            if (previouslySelected) {
                return previouslySelected;
            }

            if (document.isDocumentAcknowledgement()) {
                return new DocumentAssignment(document);
            }

            const isSingleSigner = document.signingTemplate.slots.length === 1;
            const slotAssignments: SlotAssignment[] = document.signingTemplate.slots.map((slot) => {
                const slotAssignment = new SlotAssignment(slot);

                // Inject employee as the assignment if this is a single signer document
                // Multi Signer Documents will require manual assignment through the form
                if (isSingleSigner) {
                    slotAssignment.employee = this.hiringService.employee;
                }

                return slotAssignment;
            });

            return new DocumentAssignment(document, slotAssignments);
        });
        this.documentAssignmentsChange.emit(this.documentAssignments);
    }

    onRemoveDocumentAssignment(documentAssignmentToRemove: DocumentAssignment): void {
        this.documentAssignments = this.documentAssignments.filter(
            (d: DocumentAssignment) => d.companyDocument.id !== documentAssignmentToRemove.companyDocument.id
        );
        this.documentAssignmentsChange.emit(this.documentAssignments);
        this.checkAndSetAllDocumentAssignment();
    }

    hasSigningTemplateSelected(): boolean {
        return this.documentAssignments.some((d) => d.isSigningDocument());
    }

    openAssignmentModal(documentAssignment: DocumentAssignment): void {
        this.multiSignerAssignmentForm.setup(documentAssignment);
        this.multiSignerAssignmentModal.show();
    }

    onMultiSignerAssign(): void {
        if (!this.multiSignerAssignmentForm.isValid()) {
            return;
        }

        this.multiSignerAssignmentForm.updateSlotAssignments();
        this.documentAssignmentsChange.emit(this.documentAssignments);
        this.checkAndSetAllDocumentAssignment();
        this.multiSignerAssignmentModal.hide();
        this.multiSignerAssignmentForm.reset();
    }

    private checkAndSetAllDocumentAssignment(): void {
        this.hasAllDocumentAssigned = this.documentAssignments.every((documentAssignment: DocumentAssignment) =>
            documentAssignment.hasSlotAssignmentsCompleted()
        );

        this.allDocumentAssignmentValid = this.hasAllDocumentAssigned ? true : null;
    }

    private async editHelloSignTemplate(documentAssignment: DocumentAssignment): Promise<void> {
        this.isLoading = true;
        try {
            const claimUrl = await this.hiringService.getClaimUrl(documentAssignment.companyDocument);
            this.helloSign.onSendEventOnce((data) => {
                documentAssignment.editedSignatureRequestId = data.signatureRequestId;
                this.isLoading = false;
            });
            await this.helloSign.open(claimUrl, null, true);
        } catch (e) {
            this.notify.error('forms.applicant-tracker.onboarding-documents.unableToGetPreview');
        }

        this.isLoading = false;
    }

    private async setFeatureFlag(): Promise<void> {
        this.allowMultiSignDocuments = await this.featureService.has(FeatureFlag.onboardingMultiSignDocuments);
    }
}
