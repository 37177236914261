<form #form="ngForm">
    <ng-container *ngIf="reminder">
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'forms.account-reminder-create.subject' | translate }}</mat-label>
                <input type="text" matInput required name="name" [(ngModel)]="reminder.subject" #subject="ngModel" />

                <mat-error *ngIf="subject.invalid">{{ subject.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>{{ 'forms.account-reminder-create.repeat' | translate }}</mat-label>
                <mat-select
                    name="repeatOn"
                    placeholder="{{ 'forms.account-reminder-create.never' | translate }}"
                    [(ngModel)]="reminder.repeatOn"
                >
                    <mat-option [value]="' '">{{ 'forms.account-reminder-create.never' | translate }}</mat-option>
                    <mat-option [value]="'1 day'">{{
                        'forms.account-reminder-create.everyDay' | translate
                    }}</mat-option>
                    <mat-option [value]="'1 week'">{{
                        'forms.account-reminder-create.everyWeek' | translate
                    }}</mat-option>
                    <mat-option [value]="'2 week'">{{
                        'forms.account-reminder-create.everyTwoWeeks' | translate
                    }}</mat-option>
                    <mat-option [value]="'1 month'">{{
                        'forms.account-reminder-create.everyMonth' | translate
                    }}</mat-option>
                    <mat-option [value]="'3 month'">{{
                        'forms.account-reminder-create.quarterly' | translate
                    }}</mat-option>
                    <mat-option [value]="'1 year'">{{
                        'forms.account-reminder-create.everyYear' | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <div class="columns-6 small-columns-12">
                <ui-date-picker
                    label="{{ 'forms.account-reminder-create.remindOn' | translate }}"
                    name="remindAtDate"
                    [minDate]="minDate"
                    [ngModel]="reminder.remindAt"
                    (ngModelChange)="reminder.remindAtDay = $event"
                    required
                ></ui-date-picker>
            </div>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'forms.account-reminder-create.at' | translate }}</mat-label>
                <ui-time-picker
                    #remindAtTime="ngModel"
                    name="remindAtTime"
                    [(ngModel)]="reminder.remindAtTime"
                    required
                ></ui-time-picker>
                <mat-error *ngIf="remindAtTime.invalid">{{ remindAtTime.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.account-reminder-create.reminder' | translate }}</mat-label>
                <textarea matInput name="body" required #body="ngModel" [(ngModel)]="reminder.body"></textarea>
                <mat-error *ngIf="body.invalid">{{ body.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
