<form #form="ngForm" autocomplete="off">
    <ui-banner *ngIf="pendingPayrollExists" class="margin-bottom-3" variant="warning">
        {{ 'employees.jobAndPay.pendingPayroll.cannotModifyIncomeTypes' | translate }}
    </ui-banner>

    <div *ngFor="let companyAdditionalIncome of companyAdditionalIncomes" class="app-grid centered">
        <ng-container *ngIf="companyAdditionalIncome.employeeAdditionalIncomes[0]?.active">
            <mat-form-field class="columns-10">
                <mat-label>{{ companyAdditionalIncome.displayName }}</mat-label>
                <input
                    matInput
                    [disabled]="pendingPayrollExists"
                    [name]="'amount-' + companyAdditionalIncome.name"
                    [(ngModel)]="companyAdditionalIncome.employeeAdditionalIncomes[0].amount"
                    #amount="ngModel"
                    type="number"
                    min="0.01"
                />
                <mat-error *ngIf="amount.invalid">{{ amount.errors | formErrors }}</mat-error>
            </mat-form-field>
            <ui-button
                class="columns-2"
                [disabled]="pendingPayrollExists"
                type="link"
                (click)="onRemove(companyAdditionalIncome.employeeAdditionalIncomes[0])"
                >{{ 'remove' | translate }}
            </ui-button>
        </ng-container>
    </div>
</form>
