import { Component, ViewChild } from '@angular/core';
import { DeprecatedModalFormComponent } from '@app/components';
import { Employee } from '@app/models/employee/employee.model';
import { TrainingProgramRecord } from '@app/models/training/training-program-record.model';
import { AuthService, NotifyService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { TranslateService } from '@ngx-translate/core';
import { TrainingProgramsService } from '@training/services';

type Remindable = {
    employee: Employee;
    isSelected: boolean;
    role: 'Authorized to record' | 'Enrolled employee';
};

@Component({
    selector: 'app-training-record-reminder',
    templateUrl: './training-record-reminder.template.html',
    styleUrls: ['./training-record-reminder.scss'],
})
export class TrainingRecordReminderForm extends BaseForm {
    @ViewChild('modal') modal: DeprecatedModalFormComponent;

    trainingProgramRecord: TrainingProgramRecord;
    remindables: Remindable[] = [];

    constructor(
        protected auth: AuthService,
        protected trainingProgramService: TrainingProgramsService,
        protected notify: NotifyService,
        private translateService: TranslateService
    ) {
        super();
    }

    onToggle(remindable: Remindable): void {
        remindable.isSelected = !remindable.isSelected;
        this.remindables = [...this.remindables];
    }

    open(record: TrainingProgramRecord): void {
        this.modal.isLoading = true;
        this.modal.show();
        this.remindables = [];
        this.trainingProgramRecord = record;
        this.modal.headerText = this.translateService.instant('forms.training-record.reminderHeader', {
            firstName: this.trainingProgramRecord.employee.firstName,
        });
        this.queryActionableEmployees();
    }

    async send(): Promise<void> {
        this.modal.isLoading = true;

        const employeesToRemind = this.remindables.filter((r) => r.isSelected).map((r) => r.employee);

        try {
            await this.trainingProgramService.sendReminders(this.trainingProgramRecord, employeesToRemind);
            this.notify.success('forms.training-record.reminderSent');
        } catch (error) {
            this.notify.error('forms.training-record.failedToSendReminder');
        } finally {
            this.modal.isLoading = false;
            this.modal.hide();
        }
    }

    private async queryActionableEmployees(): Promise<void> {
        //reminder form needs actionableEmployeeIds that the index view does not load (for performance reasons)
        let actionableEmployeeIds = this.trainingProgramRecord.actionableEmployeeIds;
        if (!actionableEmployeeIds) {
            const freshRecord = (await TrainingProgramRecord.with(['trainingProgram', 'employee']).find(
                this.trainingProgramRecord.id
            )) as TrainingProgramRecord;

            actionableEmployeeIds = freshRecord.actionableEmployeeIds;
        }

        const [actionableEmployees] = await Employee.param('company', this.auth.company.id)
            .with('job')
            .whereIn('id', actionableEmployeeIds)
            .get();

        this.remindables = actionableEmployees.map((employee: Employee) => ({
            role: employee.is(this.trainingProgramRecord.employee)
                ? this.translateService.instant('forms.training-record.enrolledEmployee')
                : this.translateService.instant('forms.training-record.authorizedToRecord'),
            isSelected: false,
            employee,
        }));

        this.modal.isLoading = false;
    }
}
