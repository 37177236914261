<app-deprecated-modal-form #modal [form]="this" label="dashboard.send" (save)="send()" [hideOnSave]="false">
    <ng-container *ngIf="remindables.length">
        <div class="main">
            <h3 class="ui header title">
                {{ 'forms.training-record.reminderTitle' | translate }}
                <strong>{{ trainingProgramRecord?.trainingProgram.name }}</strong>
            </h3>

            <!--Actionable employees-->
            <div class="actionable-employees-container" #container>
                <form #form="ngForm" class="ui form">
                    <div *ngFor="let remindable of remindables" class="row" (click)="onToggle(remindable)">
                        <div class="flex-container">
                            <div class="check">
                                <!-- we prevent default on the click so that it does not interfere with the onToggle above -->
                                <mat-checkbox
                                    (click)="$event.preventDefault()"
                                    [checked]="remindable.isSelected"
                                ></mat-checkbox>
                            </div>
                            <!--Employee name-->
                            <div class="name">{{ remindable.employee.fullName }}</div>

                            <!--Employee Job-->
                            <div class="position">{{ remindable.employee.job?.title || '-' }}</div>

                            <!--Employee Role on the current training program record-->
                            <div class="role">{{ remindable.role }}</div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </ng-container>
</app-deprecated-modal-form>
