import { Component } from '@angular/core';
import { Option } from '@app/components/platform/employee-autocomplete/employee-autocomplete.component';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { DocumentAssignment } from '@app/models/documents/document-assignment.model';
import { SigningTemplateSlot } from '@app/models/documents/signing-template-slot.model';
import { Employee } from '@app/models/employee/employee.model';
import { SlotAssignment } from '@app/modules/document/classes';
import { SlotAssignmentOrder } from '@app/modules/document/types/slot-assignment-order.type';
import { EmployeeHiringService } from '@app/modules/employees/services';
import { BaseForm } from '@app/modules/forms/base.form';
import { AuthService } from '@app/services';
import { TemplateSlotNamesDisplayOptions } from '@app/types/translatables/template-slot-names-display.options';

@Component({
    selector: 'app-form-onboarding-multi-signer-assignment',
    templateUrl: './onboarding-multi-signer-assignment.template.html',
    styleUrls: ['./onboarding-multi-signer-assignment.style.scss'],
})
export class OnboardingMultiSignerAssignmentForm extends BaseForm {
    documentAssignment: DocumentAssignment;
    signingTemplateSlots: SigningTemplateSlot[] = [];
    slotAssignments: SlotAssignment[] = [];
    animateIndices: boolean[];
    assigningOrder = false;
    newEmployeeOption: Option;
    employeeAutocompleteQuery: QueryFetcher = Employee.param('company', this.auth.company.id).whereIn('status', [
        'active',
    ]);
    errorMessage = null;

    slotDisplayOptions = TemplateSlotNamesDisplayOptions;

    constructor(
        protected auth: AuthService,
        private hiringService: EmployeeHiringService
    ) {
        super();
    }

    setup(documentAssignment: DocumentAssignment): void {
        this.animateIndices = this.signingTemplateSlots.map(() => false);
        this.newEmployeeOption = this.getEmployeeAutocompleteOption();
        this.documentAssignment = documentAssignment;
        // Clone Slot Assignments for Modal to Modify
        this.slotAssignments = [];
        documentAssignment.slotAssignments.forEach((slotAssignment: SlotAssignment) =>
            this.slotAssignments.push(
                Object.assign(Object.create(Object.getPrototypeOf(slotAssignment)), slotAssignment)
            )
        );
        this.signingTemplateSlots = this.slotAssignments.map(({ slot }) => slot);
        this.assigningOrder = this.slotAssignments.some((s: SlotAssignment) => s.order);
    }

    reset(): void {
        this.documentAssignment = null;
        this.slotAssignments = [];
        this.errorMessage = null;
        (this.form as any).submitted = false;
    }

    increaseOrder(slot: SlotAssignment): void {
        if (slot.order === this.signingTemplateSlots.length) {
            return;
        }

        const newOrder = (slot.order + 1) as SlotAssignmentOrder;
        const slotToSwitchWith = this.signingTemplateSlots.find((s) => s.order === newOrder);
        slotToSwitchWith.order = (slotToSwitchWith.order - 1) as SlotAssignmentOrder;

        slot.order = newOrder;
        this.setSlotAnimationIndex(newOrder - 1);
    }

    decreaseOrder(slot: SlotAssignment): void {
        if (slot.order === 1) {
            return;
        }

        const newOrder = (slot.order - 1) as SlotAssignmentOrder;
        const slotToSwitchWith = this.signingTemplateSlots.find((s) => s.order === newOrder);
        slotToSwitchWith.order = (slotToSwitchWith.order + 1) as SlotAssignmentOrder;

        slot.order = newOrder;
        this.setSlotAnimationIndex(newOrder - 1);
    }

    toggleAssigningOrder(): void {
        this.assigningOrder = !this.assigningOrder;
        this.signingTemplateSlots.forEach(
            (s, i) => (s.order = (this.assigningOrder ? i + 1 : null) as SlotAssignmentOrder)
        );
    }

    assignmentsInOrder(): SlotAssignment[] {
        if (!this.assigningOrder) {
            return this.slotAssignments.slice();
        }

        return this.slotAssignments
            .slice()
            .sort((a: SlotAssignment, b: SlotAssignment) => (this.assigningOrder ? a.order - b.order : 0));
    }

    isValid(): boolean {
        if (this.hasNotAssignedNewEmployee()) {
            this.errorMessage = 'forms.applicant-tracker.onboarding-documents.youMustAssignTheOnboarding';
            return false;
        }

        if (this.hasDuplicateAssignments()) {
            this.errorMessage =
                'forms.applicant-tracker.onboarding-documents.cannotAssignTheSameEmployeeToMultipleRoles';
            return false;
        }

        this.errorMessage = null;
        return true;
    }

    updateSlotAssignments(): void {
        this.slotAssignments.forEach((slotAssignment) => {
            // If order is 0, we need to update it to null - if it's an integer it needs to be between 1:4
            slotAssignment.slot.order = slotAssignment.order === 0 ? null : slotAssignment.order;
        });
        this.documentAssignment.slotAssignments = this.slotAssignments;
    }

    hasNotAssignedNewEmployee(): boolean {
        // If every Assignee has an ID, the new employee is not selected
        return this.slotAssignments.every((s: SlotAssignment) => s.employee.isPersisted);
    }

    hasDuplicateAssignments(): boolean {
        return (
            new Set(this.slotAssignments.map((slot: SlotAssignment) => slot.employee.id)).size !==
            this.slotAssignments.length
        );
    }

    isSubmitted(): boolean {
        return this.form.submitted;
    }

    private setSlotAnimationIndex(index: number): void {
        this.animateIndices = this.animateIndices.map((_) => false);
        setTimeout(() => {
            this.animateIndices[index] = true;
        });
    }

    private getEmployeeAutocompleteOption(): Option {
        // Create the Static Option in Employee Select for new employee
        const employee = this.hiringService.employee;
        return {
            label: employee.fullName,
            value: employee,
            hasAvatar: !!employee.avatarId,
            avatarable: true,
            avatarLabel: employee.firstName?.charAt(0).toUpperCase() + employee.lastName?.charAt(0).toUpperCase(),
        };
    }
}
