import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BulkSelectOffboardingRemindersComponent, DeprecatedModalComponent } from '@app/components';
import { SocketEventTypes } from '@app/enums';
import { Task } from '@app/models/tasks/task.model';
import { AuthService, SocketService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { OffboardingReminder } from '@models/employee/offboarding-reminder.model';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';
import { TaskTemplate } from '@models/tasks/task-template.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-employees-form-terminate-offboarding',
    templateUrl: './offboarding.form.html',
    styleUrls: ['./offboarding.style.scss'],
})
export class TerminateOffboardingForm extends BaseForm implements OnInit, OnDestroy {
    @ViewChild('selectReminders', { static: true }) selectReminders: BulkSelectOffboardingRemindersComponent;
    @ViewChild('applyTasksModal', { static: true }) applyTasksModal: DeprecatedModalComponent;
    @Input() employee: Employee;
    @Input() reminders: OffboardingReminder[] = [];
    @Input() selectedTaskTemplates: TaskTemplate[] = [];
    @Output() remindersChange: EventEmitter<OffboardingReminder[]> = new EventEmitter<OffboardingReminder[]>();
    @Output() selectedTaskTemplatesChange: EventEmitter<TaskTemplate[]> = new EventEmitter<TaskTemplate[]>();

    isLoading = true;

    showOffboardingTasks = false;
    showCreateTaskTemplateModal = false;

    autoMatchedTaskTemplates: TaskTemplate[] = [];
    otherTaskTemplates: TaskTemplate[] = [];

    companyChannelSubscription: Subscription = null;

    constructor(
        private socket: SocketService,
        protected auth: AuthService
    ) {
        super();

        this.companyChannelSubscription = this.socket
            .getCompanyChannel(SocketEventTypes.newTaskTemplateCreated)
            .subscribe(() => {
                this.setAutoMatchedTaskTemplates();
                this.setOtherTaskTemplates();
            });
    }

    ngOnInit(): void {
        // set showOnboardingTasks based on task permission
        this.showOffboardingTasks = this.auth.can(Task.managePermission);

        this.setAutoMatchedTaskTemplates();
        this.setOtherTaskTemplates();

        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.companyChannelSubscription.unsubscribe();
    }

    onSelectOffboardingReminders(): void {
        this.selectReminders.show(this.reminders).then((reminders: OnboardingReminder[]) => {
            if (reminders) {
                this.reminders = reminders;
                this.remindersChange.emit(this.reminders);
                return;
            }
            this.reminders = [];
            this.remindersChange.emit(this.reminders);
        });
    }

    onRemoveOffboardingReminder(reminder: OffboardingReminder): void {
        this.reminders = this.reminders.filter((r: OffboardingReminder) => r.id !== reminder.id);
        this.remindersChange.emit(this.reminders);
    }

    onAddTask(): void {
        this.setAutoMatchedTaskTemplates();
        this.setOtherTaskTemplates();

        this.displayTasksModal();
    }

    onApplyTaskTemplates(selectedTaskTemplates: TaskTemplate[]): void {
        this.selectedTaskTemplates = selectedTaskTemplates ? [...selectedTaskTemplates] : [];
        this.selectedTaskTemplatesChange.emit(this.selectedTaskTemplates);
    }

    onRemoveTask(removeTaskTemplate: TaskTemplate): void {
        this.selectedTaskTemplates = this.selectedTaskTemplates.filter(
            (taskTemplate: TaskTemplate) => taskTemplate.id !== removeTaskTemplate.id
        );
        this.selectedTaskTemplatesChange.emit(this.selectedTaskTemplates);
    }

    private async setAutoMatchedTaskTemplates(): Promise<void> {
        const [autoMatchedTaskTemplates] = await TaskTemplate.where('task_type', 'offboarding')
            .where('filterMatching', this.employee.id)
            .all();
        this.autoMatchedTaskTemplates = autoMatchedTaskTemplates;
    }

    private async setOtherTaskTemplates(): Promise<void> {
        const [otherTaskTemplates] = await TaskTemplate.where('task_type', 'offboarding')
            .where('filterNonMatching', this.employee.id)
            .all();
        this.otherTaskTemplates = otherTaskTemplates;
    }

    private async displayTasksModal(): Promise<void> {
        if (!this.autoMatchedTaskTemplates.length && !this.otherTaskTemplates.length) {
            this.showCreateTaskTemplateModal = true;
            return;
        }

        this.showCreateTaskTemplateModal = false;
        this.applyTasksModal.show();
    }
}
