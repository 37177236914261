import { Component, Input } from '@angular/core';
import { EmployeeRehireDetails } from '@app/modules/employees/interfaces/employee-rehire-details.interface';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-employees-form-rehire-information',
    templateUrl: './information.form.html',
})
export class RehireInformationForm extends BaseForm {
    @Input() employee: Employee;
    @Input() rehireDetails: EmployeeRehireDetails;
}
