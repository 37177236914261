<form #form="ngForm">
    <ui-banner class="margin-bottom-3" *ngIf="errorMessage" variant="danger">
        <p class="margin-right-1">{{ errorMessage | appTranslate }}</p>
    </ui-banner>

    <ui-banner class="margin-bottom-3" *ngIf="assigningOrder" variant="info">
        <p class="margin-right-1">
            {{
                'forms.applicant-tracker.onboarding-documents.pleaseNoteThatThisDocumentHasASigningOrderAndTheOnboardingLinkWi'
                    | appTranslate
            }}
        </p>
    </ui-banner>

    <!-- For each slot, show employee selector -->
    <div
        *ngFor="let assignment of assignmentsInOrder(); let i = index"
        class="slot"
        [class.animate]="animateIndices[i]"
    >
        <div class="order-selector" *ngIf="assigningOrder">
            <mat-icon (click)="decreaseOrder(assignment)" svgIcon="chevronUp"></mat-icon>
            <mat-icon (click)="increaseOrder(assignment)" svgIcon="chevronDown"></mat-icon>
        </div>

        <div class="employee-selector">
            <mat-form-field>
                <mat-label>
                    <span class="slot-name">
                        <ng-container *ngIf="assigningOrder">{{ assignment.order }}.</ng-container
                        >{{ assignment.slot.name | appTranslateOptions: slotDisplayOptions }}</span
                    ></mat-label
                >
                <ui-employee-autocomplete
                    [name]="'employee' + i"
                    [attr.name]="'employee' + i"
                    [customQuery]="employeeAutocompleteQuery"
                    required
                    [additionalOptions]="[newEmployeeOption]"
                    [(ngModel)]="assignment.employee"
                    [disabled]="assignment.permanentSlotAssignment !== null"
                    #employeeSelector="ngModel"
                >
                </ui-employee-autocomplete>
                <mat-error *ngIf="employeeSelector.invalid">{{ employeeSelector.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- Checkbox for ordering -->
    <div class="ui divider"></div>
    <div>
        <mat-checkbox
            class="align-left"
            name="assigningOrder"
            [ngModel]="assigningOrder"
            (ngModelChange)="toggleAssigningOrder()"
            >{{ 'forms.applicant-tracker.onboarding-documents.assignSigningOrder' | appTranslate }}
        </mat-checkbox>
    </div>
</form>
