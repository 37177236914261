<form #form="ngForm">
    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'employees.records.title' | translate }}</mat-label>
            <input matInput type="text" name="title" [(ngModel)]="record.title" #title="ngModel" required />
            <mat-error *ngIf="title.invalid">{{ title.errors | formErrors }}</mat-error>
        </mat-form-field>
        <mat-form-field class="columns-12">
            <mat-label>{{ 'employees.records.body' | translate }}</mat-label>
            <ui-rich-text name="body" height="200px" [(ngModel)]="record.body" #body="ngModel" required></ui-rich-text>
            <mat-error *ngIf="body.invalid">{{ body.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
