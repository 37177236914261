<form class="app-grid" #form="ngForm">
    <mat-form-field class="columns-3">
        <mat-label>{{ 'forms.employees-rehire.rehireDate' | translate }}</mat-label>
        <input
            matInput
            required
            name="nextDayOfWorkOn"
            [min]="employee.lastDayOfWorkOn"
            [(ngModel)]="employee.nextDayOfWorkOn"
            [matDatepicker]="nextDayOfWorkOn"
        />
        <mat-datepicker-toggle matSuffix [for]="nextDayOfWorkOn">
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #nextDayOfWorkOn></mat-datepicker>
    </mat-form-field>
</form>
