import { Component, Input, OnChanges } from '@angular/core';
import { EmployeeRehireDetails } from '@app/modules/employees/interfaces/employee-rehire-details.interface';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import moment from 'moment';

@Component({
    selector: 'app-employees-form-rehire-review',
    templateUrl: './review.form.html',
    styleUrls: ['./review.style.scss'],
})
export class RehireReviewForm extends BaseForm implements OnChanges {
    @Input() employee: Employee;

    @Input() rehireDetails: EmployeeRehireDetails;
    rehireWithinOneYear: boolean;
    rehireInPast: boolean;

    ngOnChanges(): void {
        this.rehireInPast = moment(this.employee.nextDayOfWorkOn).isBefore(moment());

        this.rehireWithinOneYear = moment().isBefore(
            moment(this.employee.lastDayOfWorkOn).add(1, 'y').format('MM-DD-YYYY')
        );
    }
}
