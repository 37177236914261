import { Component, Input } from '@angular/core';
import { OnboardingReminderForm } from '../onboarding-reminder/onboarding-reminder.form';
import { OffboardingReminder } from '@models/employee/offboarding-reminder.model';

@Component({
    selector: 'app-form-offboarding-reminder',
    templateUrl: './offboarding-reminder.template.html',
})
export class OffboardingReminderForm extends OnboardingReminderForm {
    @Input() reminder: OffboardingReminder = new OffboardingReminder();
}
