<form #form="ngForm" crossDate>
    <div class="app-grid">
        <div class="columns-4 small-columns-12">
            <ui-date-picker
                label="{{ 'forms.employees-leaveOfAbsence.startDate' | appTranslate }}"
                name="startDate"
                [ngModel]="leaveOfAbsence.start"
                (ngModelChange)="onChangeDate($event)"
                placeholder="{{ 'forms.employees-leaveOfAbsence.selectDatePlaceholder' | appTranslate }}"
                required
                #startDate="ngModel"
                [disabled]="disableStartDate"
            ></ui-date-picker>
        </div>
    </div>

    <div class="app-grid">
        <div class="columns-4 small-columns-12">
            <ui-date-picker
                label="{{ 'forms.employees-leaveOfAbsence.endDateIfKnown' | appTranslate }}"
                name="endDate"
                [minDate]="minDateForEndDate"
                [(ngModel)]="leaveOfAbsence.end"
                #endDate="ngModel"
                placeholder="{{ 'forms.employees-leaveOfAbsence.selectDatePlaceholder' | appTranslate }}"
            ></ui-date-picker>
        </div>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.employees-leaveOfAbsence.leaveType' | appTranslate }}</mat-label>
            <mat-select
                name="leaveType"
                [(ngModel)]="leaveOfAbsence.leaveOfAbsenceTypeId"
                #leaveType="ngModel"
                placeholder="{{ 'forms.employees-leaveOfAbsence.leaveTypePlaceholder' | appTranslate }}"
                [disabled]="disableLeaveType"
                required
            >
                <mat-option [value]="leaveOfAbsenceType.id" *ngFor="let leaveOfAbsenceType of leaveOfAbsenceTypes">
                    {{ leaveOfAbsenceType.name | appTranslateOptions: leaveOfAbsenceTypesDisplayOptions }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="leaveType.invalid">{{ leaveType.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
    <div class="app-grid">
        <mat-form-field class="columns-4">
            <mat-label>{{ 'forms.employees-leaveOfAbsence.descriptionIfNeeded' | appTranslate }}</mat-label>
            <textarea #description="ngModel" name="description" [(ngModel)]="leaveOfAbsence.description" matInput>
            </textarea>
            <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
            <mat-hint>{{ 'forms.employees-leaveOfAbsence.descriptionHint' | appTranslate }}</mat-hint>
        </mat-form-field>
    </div>
</form>
