import { subYears } from 'date-fns';

/*
 * Minimum date for date of birth (1900-01-01)
 */
export const minBornOnDate = new Date(1900, 0, 1);

/*
 * Maximum date for date of birth (Current date less five years)
 */
export const maxBornOnDate = subYears(new Date(), 5);
