<form #form="ngForm">
    <p class="description">
        {{ 'forms.applicant-tracker.onboarding.selectAnyPoliciesThatYouLikeToAssign' | appTranslate }}
    </p>
    <div class="app-grid" *ngFor="let timeOffType of timeOffTypes">
        <!--Policy-->
        <mat-form-field class="columns-6">
            <mat-label appRemoveAriaOwns>
                <mat-icon [svgIcon]="timeOffType.icon" [ngStyle]="{ color: timeOffType.color }"></mat-icon>
                <span>{{ timeOffType.name }}</span>
            </mat-label>
            <mat-select
                placeholder="{{ 'forms.applicant-tracker.onboarding.selectPolicy' | appTranslate }}"
                name="timeOffType-{{ timeOffType.id }}"
                [ngModel]="findSelectedPolicy(timeOffType)?.timeOffPolicy?.id"
                (ngModelChange)="setTimeOffPolicy(timeOffType, $event)"
            >
                <mat-option [value]="null"> {{ 'none' | appTranslate }} </mat-option>
                <mat-option *ngFor="let timeOffPolicy of timeOffType.timeOffPolicies" [value]="timeOffPolicy.id">
                    {{ timeOffPolicy.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <!--Effective Date-->
        <mat-form-field class="columns-6" *ngIf="!!findSelectedPolicy(timeOffType)?.timeOffPolicy?.id">
            <mat-label appRemoveAriaOwns>{{
                'forms.applicant-tracker.onboarding.accrualStartDate' | appTranslate
            }}</mat-label>
            <input
                name="accrualStartDate-{{ timeOffType.id }}"
                #accrualStartDate="ngModel"
                required
                [(ngModel)]="findSelectedPolicy(timeOffType).effectiveAt"
                [matDatepicker]="effectiveDatePicker"
                matInput
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <mat-error *ngIf="accrualStartDate.invalid">{{ accrualStartDate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
