<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="employee && bankAccount">
        <div class="app-grid">
            <mat-form-field>
                <mat-label appRemoveAriaOwns>{{ 'forms.bankInformation.paymentMethod' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'forms.bankInformation.selectAMethod' | translate }}"
                    name="paymentMethod"
                    #paymentMethod="ngModel"
                    [(ngModel)]="employee.paymentMethod"
                    (ngModelChange)="onPaymentMethodChange()"
                    required
                    matInput
                    [disabled]="disablePaymentMethod()"
                    matTooltip="Contact Support to change the default payment method"
                    [matTooltipDisabled]="!disablePaymentMethod()"
                >
                    <mat-option value="">{{ 'forms.bankInformation.selectAnOption' | translate }}</mat-option>
                    <mat-option value="direct deposit">{{
                        'forms.bankInformation.directDeposit' | translate
                    }}</mat-option>
                    <mat-option value="cheque">{{ 'forms.bankInformation.cheque' | translate }}</mat-option>
                    <mat-option value="other">{{ 'forms.bankInformation.other' | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="paymentMethod.invalid">{{ paymentMethod.errors | formErrors }}</mat-error>
            </mat-form-field>

            <ui-banner variant="warning" class="margin-bottom-2" *ngIf="showDirectDepositWarning()">
                <div class="flex width-full justify-space-between align-items-start">
                    <p class="text-align-left">
                        Once direct deposit is saved as the payment method, you’ll have to contact Support to change the
                        default payment method. You will still be able to edit or update the bank account info later.
                    </p>
                </div>
            </ui-banner>

            <mat-form-field>
                <mat-label>{{ 'forms.bankInformation.bankInstituteNumber' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="000"
                    #bankInstitution="ngModel"
                    name="bankInstitution"
                    [required]="bankingInformationRequired"
                    minlength="3"
                    pattern="[0-9]{3}"
                    [(ngModel)]="bankAccount.institutionNumber"
                    (ngModelChange)="onBankingInformationChange()"
                    [disabled]="disableBankInformation()"
                    matInput
                />
                <mat-hint>{{ 'forms.bankInformation.bankInstituteNumberHelperText' | appTranslate }}</mat-hint>
                <mat-error *ngIf="bankInstitution.invalid">{{ bankInstitution.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top-2">
                <mat-label>{{ 'forms.bankInformation.bankTransitNumber' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="12345"
                    #bankBranch="ngModel"
                    name="bankBranch"
                    [required]="bankingInformationRequired"
                    minlength="5"
                    pattern="[0-9]{5}"
                    [(ngModel)]="bankAccount.branchNumber"
                    (ngModelChange)="onBankingInformationChange()"
                    [disabled]="disableBankInformation()"
                    matInput
                />
                <mat-hint>{{ 'forms.bankInformation.bankTransitNumberHelperText' | appTranslate }}</mat-hint>
                <mat-error *ngIf="bankBranch.invalid">{{ bankBranch.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="margin-top-2">
                <mat-label>{{ 'forms.bankInformation.bankAccountNumber' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="1234567"
                    #accountNumber="ngModel"
                    name="accountNumber"
                    [required]="bankingInformationRequired"
                    minlength="5"
                    maxlength="12"
                    pattern="[0-9]+"
                    [(ngModel)]="bankAccount.accountNumber"
                    (ngModelChange)="onBankingInformationChange()"
                    [disabled]="disableBankInformation()"
                    matInput
                />
                <mat-hint>{{ 'forms.bankInformation.bankAccountNumberHelperText' | appTranslate }}</mat-hint>
                <mat-error *ngIf="accountNumber.invalid">{{ accountNumber.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
