import { Component, Input, OnChanges } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { OffboardingReminder } from '@models/employee/offboarding-reminder.model';
import moment from 'moment';

@Component({
    selector: 'app-employees-form-terminate-review',
    templateUrl: './review.form.html',
    styleUrls: ['./review.style.scss'],
})
export class TerminateReviewForm extends BaseForm implements OnChanges {
    @Input()
    employee: Employee;

    @Input()
    offboardingReminders: OffboardingReminder[] = [];

    @Input() employeeIsOnTimeTracking = false;

    terminationInPast: boolean | null = null;
    lastDayOfAccessToHumi: moment.Moment | null = null;

    @Input() hasManagedBenefitPolicy: boolean;
    @Input() showBenefitExtensionOption: boolean;
    @Input() isNotProtectedLeave: boolean | undefined;
    @Input() isNotQuitLeave: boolean | undefined;

    @Input() benefitExtensionDate: Date | null = null;
    @Input() lastDayOfWorkOn: Date | null = null;
    @Input() shouldShowBackDatedBanner = false;
    @Input() lastPaidRecurringPayrollEndDate: Date | null = null;

    ngOnChanges(): void {
        if (this.employee.lastDayOfWorkOn && moment(this.employee.lastDayOfWorkOn).isValid()) {
            const now = moment();
            //'day' to make sure comparison is made ONLY taking day/month/year into account
            this.terminationInPast = moment(this.employee.lastDayOfWorkOn).isSameOrBefore(now, 'day');
            this.lastDayOfAccessToHumi = moment(this.employee.lastDayOfWorkOn).add(1, 'day');
            return;
        }

        this.terminationInPast = null;
        this.lastDayOfAccessToHumi = null;
    }
}
