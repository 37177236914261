<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="employee && salary">
        <!-- Banner explaining why the submit button is disabled -->
        <div *ngIf="disabled" style="margin-bottom: 20px">
            <!-- Banner for payroll admins -->
            <ui-flag-deprecated
                *ngIf="isPayrollAdmin"
                icon="Exclamation"
                iconType="danger"
                header="{{ 'forms.employees-salary.pendingPayroll' | appTranslate }}"
            >
                <p>{{ 'forms.employees-salary.youHaveAPendingPayroll' | appTranslate }}</p>
            </ui-flag-deprecated>

            <!-- Banner for users who are not payroll admins -->
            <ui-flag-deprecated
                *ngIf="!isPayrollAdmin"
                icon="Exclamation"
                iconType="danger"
                header="{{ 'forms.employees-salary.thereIsAPendingPayroll' | appTranslate }}"
            >
                <p>{{ 'forms.employees-salary.thisUpdateAffectsAPendingPayroll' | appTranslate }}</p>
            </ui-flag-deprecated>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-12">
                <mat-label appRemoveAriaOwns>{{ 'forms.employees-salary.compensationType' | appTranslate }}</mat-label>
                <mat-select
                    placeholder="Select a type..."
                    name="compensationType"
                    required
                    placeholder="{{ 'forms.employees-salary.selectAnOption' | appTranslate }}"
                    #compensationType="ngModel"
                    [(ngModel)]="salary.frequency"
                    (ngModelChange)="setHoursPerWeek()"
                >
                    <mat-option [value]="'year'">{{ 'forms.employees-salary.yearly' | appTranslate }}</mat-option>
                    <mat-option [value]="'hour'">{{ 'forms.employees-salary.hourly' | appTranslate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="compensationType.invalid">{{ compensationType.errors | formErrors }}</mat-error>
            </mat-form-field>

            <!-- Payroll Clients -->
            <mat-form-field *ngIf="employee.isPayrollSyncEnabled" class="columns-12">
                <mat-label>{{
                    (salary.frequency === 'year' ? 'forms.employees-salary.salary' : 'forms.employees-salary.rate')
                        | appTranslate
                }}</mat-label>
                <input
                    matInput
                    type="text"
                    [textMask]="{ mask: currencyMask }"
                    name="rate"
                    min="0"
                    #payrollSyncedRate="ngModel"
                    required
                    [ngModel]="salary.rate"
                    (ngModelChange)="salary.rate = stripNonNumeric($event); resetCursorPosition()"
                />
                <span matSuffix>{{ 'forms.employees-salary.cad' | appTranslate }}</span>
                <mat-error *ngIf="payrollSyncedRate.invalid">{{ payrollSyncedRate.errors | formErrors }}</mat-error>
            </mat-form-field>

            <!-- Non-payroll Clients -->
            <ng-container *ngIf="!employee.isPayrollSyncEnabled">
                <mat-form-field class="columns-12">
                    <mat-label>{{
                        (salary.frequency === 'year' ? 'forms.employees-salary.salary' : 'forms.employees-salary.rate')
                            | appTranslate
                    }}</mat-label>
                    <input
                        matInput
                        type="number"
                        placeholder="{{ 'forms.employees-salary.egOr' | appTranslate }}"
                        name="rate"
                        min="0"
                        required
                        #rate="ngModel"
                        [(ngModel)]="salary.rate"
                    />
                    <mat-error *ngIf="rate.invalid">{{ rate.errors | formErrors }}</mat-error>
                </mat-form-field>

                <mat-form-field class="columns-12">
                    <mat-label appRemoveAriaOwns>{{ 'forms.employees-salary.currency' | appTranslate }}</mat-label>
                    <mat-select name="currency" required #currency="ngModel" [(ngModel)]="employee.currency">
                        <mat-option>{{ 'forms.employees-salary.none' | appTranslate }}</mat-option>
                        <mat-option *ngFor="let currency of currencies" [value]="currency.code"
                            >{{ currency.code }} - {{ currency.name }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="currency.invalid">{{ currency.errors | formErrors }}</mat-error>
                </mat-form-field>
            </ng-container>

            <mat-form-field *ngIf="salary.frequency" class="columns-12">
                <mat-label>{{ 'forms.employees-salary.hoursPerWeek' | appTranslate }}</mat-label>
                <input
                    matInput
                    type="number"
                    placeholder="{{ 'forms.employees-salary.enterAmount' | appTranslate }}"
                    name="hoursPerWeek"
                    min="1"
                    [required]="salary.frequency"
                    #hoursPerWeek="ngModel"
                    [(ngModel)]="salary.hoursPerWeek"
                />
                <mat-error *ngIf="hoursPerWeek.invalid"> {{ hoursPerWeek.errors | formErrors }} </mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.employees-salary.effectiveDate' | appTranslate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    name="effectiveAt"
                    #salaryEffectiveAt="ngModel"
                    [ngModel]="salary.effectiveAt | appDate: 'yyyy-MM-dd'"
                    (ngModelChange)="setFormEnabledStatus(); salary.effectiveAt = $event"
                    [matDatepicker]="effectiveAtPicker"
                />
                <mat-datepicker-toggle matSuffix [for]="effectiveAtPicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #effectiveAtPicker></mat-datepicker>

                <mat-error *ngIf="salaryEffectiveAt.invalid">{{ salaryEffectiveAt.errors | formErrors }}</mat-error>
            </mat-form-field>

            <ng-container *ngIf="showVacationPay">
                <ui-button
                    type="link"
                    *ngIf="!showVacationPayPercentage; else vacationDetails"
                    (click)="showVacationPayPercentage = true"
                    >{{ 'forms.employees-salary.vacationPay' | appTranslate }}
                </ui-button>

                <ng-template #vacationDetails>
                    <div class="app-grid centered">
                        <mat-form-field class="columns-9">
                            <mat-label>{{ 'forms.employees-salary.vacationPayPercentage' | appTranslate }}</mat-label>
                            <input
                                matInput
                                type="text"
                                [textMask]="{ mask: percentageMask }"
                                name="vacationPayPercentage"
                                [ngModel]="salary.vacationPayPercentage"
                                (ngModelChange)="
                                    salary.vacationPayPercentage = stripNonNumeric($event); resetCursorPosition()
                                "
                            />
                            <mat-hint>{{
                                'forms.employees-salary.percentageOfGrossPayPerPeriod' | appTranslate
                            }}</mat-hint>
                        </mat-form-field>
                        <ui-button
                            class="columns-3"
                            type="link"
                            [disabled]="
                                salary.vacationPayPercentage === null || salary.vacationPayPercentage === undefined
                            "
                            (click)="onRemoveVacationPay()"
                            >{{ 'forms.employees-salary.remove' | appTranslate }}
                        </ui-button>
                    </div>
                </ng-template>
            </ng-container>
        </div>
    </ng-container>
</form>
