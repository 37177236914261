import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { Email } from '@models/account/email.model';

@Component({
    selector: 'app-form-email',
    templateUrl: './email.template.html',
})
export class EmailForm extends BaseForm {
    @Input() email: Email = new Email();
}
