<form #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <div class="app-grid">
        <ng-container *ngIf="dataValues">
            <ng-container *ngFor="let field of filteredDataFields; let index = index">
                <ng-container *ngIf="dataValues[getDataValueIndexForField(field.id)]">
                    <!--
                    We keep this outside of a mat-label because the labels can be set by a user
                    and can overflow the mat-label container as Material doesn't handle long label names well.
                -->
                    <label class="input-label margin-top-1" [for]="'field-' + index"
                        >{{ field.name | ucfirst }}<span *ngIf="field.isRequired">*</span></label
                    >

                    <!-- Long Text -->
                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.longText">
                        <textarea
                            #textArea="ngModel"
                            matInput
                            rows="5"
                            maxlength="1000"
                            type="text"
                            name="field-{{ index }}"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            [attr.placeholder]="field.description"
                            [dataFieldTypeRules]="field.rules"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].value"
                            [required]="field.isRequired"
                            [errorStateMatcher]="errorStateMatcher"
                        ></textarea>
                        <mat-error *ngIf="textArea.invalid">{{ textArea.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <!-- Dropdown -->
                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.dropdown">
                        <mat-select
                            name="field-{{ index }}"
                            [placeholder]="field.description"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].dataFieldOptionId"
                            [required]="field.isRequired"
                            #dropdown="ngModel"
                        >
                            <!-- If the field is not required we want to give the user a way null the value -->
                            <mat-option *ngIf="!field.isRequired" [value]="null">{{ field.description }}</mat-option>
                            <mat-option
                                *ngFor="
                                    let dataFieldOption of field.getFormattedDropdownOption();
                                    trackBy: trackByFunction
                                "
                                [value]="dataFieldOption.value"
                                >{{ dataFieldOption.label }}</mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="dropdown.invalid">{{ dropdown.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <!--Text-->
                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.shortText">
                        <input
                            matInput
                            type="text"
                            name="field-{{ index }}"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            [attr.placeholder]="field.description"
                            [dataFieldTypeRules]="field.rules"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].value"
                            [required]="field.isRequired"
                            [errorStateMatcher]="errorStateMatcher"
                            #text="ngModel"
                        />
                        <mat-error *ngIf="text.invalid">{{ text.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.boolean">
                        <!-- Boolean -->
                        <mat-select
                            name="field-{{ index }}"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            [dataFieldTypeRules]="field.rules"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].value"
                            [required]="field.isRequired"
                            [errorStateMatcher]="errorStateMatcher"
                            #boolean="ngModel"
                        >
                            <mat-option [value]="'1'">Yes</mat-option>
                            <mat-option [value]="'0'">No</mat-option>
                        </mat-select>
                        <mat-error *ngIf="boolean.invalid">{{ boolean.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <!--Date-->
                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.date">
                        <input
                            matInput
                            [attr.placeholder]="field.description"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            name="field-{{ index }}"
                            [dataFieldTypeRules]="field.rules"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].value"
                            #date="ngModel"
                            [matDatepicker]="picker"
                            [required]="field.isRequired"
                            [errorStateMatcher]="errorStateMatcher"
                        />
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="date.invalid">{{ date.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <!-- Number -->
                    <mat-form-field [ngClass]="columnClass" *ngIf="field.type === dataFieldTypes.number">
                        <input
                            matInput
                            type="number"
                            [attr.min]="field.min"
                            [attr.max]="field.max"
                            [id]="'field-' + index"
                            [attr.name]="'field-' + index"
                            name="field-{{ index }}"
                            [attr.placeholder]="field.description"
                            [dataFieldTypeRules]="field.rules"
                            (ngModelChange)="onDataValueChange()"
                            [(ngModel)]="dataValues[getDataValueIndexForField(field.id)].value"
                            #number="ngModel"
                            [required]="field.isRequired"
                            [errorStateMatcher]="errorStateMatcher"
                        />
                        <mat-error *ngIf="number.invalid">{{ number.errors | formErrors }}</mat-error>
                    </mat-form-field>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</form>
