import { Component, ElementRef, Input } from '@angular/core';
import { AuthService, NotifyService } from '@app/services';
import { Employee } from '@models/employee/employee.model';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-onboarding-reminder',
    templateUrl: './onboarding-reminder.template.html',
})
export class OnboardingReminderForm extends BaseForm {
    @Input() reminder: OnboardingReminder = new OnboardingReminder();
    remindBefore = 'on';
    remindToType: string;
    employee: Employee = null;

    constructor(
        protected element: ElementRef,
        protected auth: AuthService,
        protected notify: NotifyService
    ) {
        super();
    }

    setReminder(reminder: OnboardingReminder): void {
        this.reminder = reminder;

        if (Number(this.reminder.remindTo) > 0) {
            this.remindToType = 'colleague';
            Employee.param('company', this.auth.company.id)
                .find(this.reminder.remindTo)
                .then((employee: Employee) => {
                    this.employee = employee;
                })
                .catch((err) => this.notify.error(err || 'Unable to retrieve colleague'));
        } else {
            this.remindToType = this.reminder.remindTo;
        }

        if (this.reminder.delayDays === 0) {
            this.remindBefore = 'on';
        } else if (this.reminder.remindBefore === true) {
            this.remindBefore = 'before';
        } else {
            this.remindBefore = 'after';
        }
    }

    setColleague(colleague: Employee): void {
        if (!colleague) {
            return;
        }

        this.reminder.remindTo = '' + colleague.id;
        this.reminder.colleagueId = '' + colleague.id;
    }

    setRemindToType(val: string): void {
        if (val === 'colleague') {
            this.remindToType = 'colleague';
            return;
        }

        this.reminder.remindTo = val;
        this.remindToType = val;
    }

    setRemindBefore(value: string): void {
        switch (value) {
            case 'before':
                this.reminder.remindBefore = true;
                break;
            case 'after':
                this.reminder.remindBefore = false;
                break;
            default:
                this.reminder.remindBefore = true;
                this.reminder.delayDays = 0;
                break;
        }
    }
}
