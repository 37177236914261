import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { makeCurrencyMask, makePercentageMask } from '@app/functions';
import { resetCursorPosition } from '@app/functions/reset-cursor-position';
import { stripNonNumeric } from '@app/functions/strip-non-numeric';
import { PendingPayrollStatus } from '@app/interfaces/pending-payroll-status.interface';
import { Currency } from '@app/models/common/currency.model';
import { AuthService, CurrencyService, PayrollService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { Salary } from '@models/employee/salary.model';
import { isBefore, isSameDay } from 'date-fns';

@Component({
    selector: 'app-form-salary',
    templateUrl: './salary.template.html',
})
export class SalaryForm extends BaseForm implements OnInit {
    @ViewChild('form') form;
    @Input() employee: Employee;
    @Input() salary: Salary = new Salary();
    @Input() isEditMode = false;
    disabled = false;
    pendingPayrollEndDate: Date | null = null;
    isPayrollAdmin = false;
    currencies: Currency[] = [];

    currencyMask = makeCurrencyMask();

    percentageMask = makePercentageMask();

    showVacationPayPercentage = false;

    get showVacationPay(): boolean {
        return this.employee.is(this.auth.employee) || this.auth.isAdmin();
    }

    constructor(
        private auth: AuthService,
        private payrollService: PayrollService,
        private currencyService: CurrencyService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        if (this.employee.isPayrollSyncEnabled) {
            this.checkPendingPayrollStatus();
        }
        this.currencies = await this.currencyService.getAllCurrencies();
    }

    setHoursPerWeek() {
        if (this.salary.hoursPerWeek === null || this.salary.hoursPerWeek === undefined) {
            this.salary.hoursPerWeek = 40;
        }
    }

    reset(): void {
        this.salary = new Salary();
        this.form.reset();
        this.form.submitted = false;
    }

    beforeShow(): void {
        this.showVacationPayPercentage = this.isEditMode && this.salary.vacationPayPercentage > 0;
        if (!this.isEditMode) {
            this.reset();
        }

        if (this.employee.isPayrollSyncEnabled) {
            this.employee.currency = 'CAD';
        }

        this.isPayrollAdmin = this.auth.can('accessPayroll');
    }

    stripNonNumeric(val: string | number): number | null {
        if (!val) {
            return null;
        }

        return stripNonNumeric(val);
    }

    onRemoveVacationPay(): void {
        this.salary.vacationPayPercentage = null;
        this.showVacationPayPercentage = false;
    }

    resetCursorPosition(): void {
        resetCursorPosition();
    }

    /**
     * We disable this form if there is a pending payroll
     * and the new salary start date is before the pending payroll's end date
     * If this logic changes, this function should be modified
     */
    setFormEnabledStatus(): void {
        if (!(this.employee.isPayrollSyncEnabled && this.pendingPayrollEndDate && this.salary.effectiveAt)) {
            this.disabled = false;

            return;
        }

        const effectiveBeforePayrollEnds = isBefore(this.salary.effectiveAt, this.pendingPayrollEndDate);
        const effectiveOnSameDayPayrollEnds = isSameDay(this.salary.effectiveAt, this.pendingPayrollEndDate);

        this.disabled = effectiveBeforePayrollEnds || effectiveOnSameDayPayrollEnds;
    }

    private checkPendingPayrollStatus(): void {
        this.payrollService
            .getPendingPayrollStatus()
            .then(({ endDate }: PendingPayrollStatus) => (this.pendingPayrollEndDate = endDate));
    }
}
