import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { BaseForm } from '@forms/base.form';
import { BankAccount } from '@models/employee/bank-account.model';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-form-bank-account',
    templateUrl: './bank-account.template.html',
})
export class BankAccountForm extends BaseForm implements OnInit {
    @Input() employee: Employee;
    @Input() bankAccount: BankAccount;

    @Input() bankingInformationRequiredByDefault;
    bankingInformationRequired: boolean;

    constructor(private changeDetector: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        this.onBankingInformationChange();
    }

    onBankingInformationChange(): void {
        if (this.bankingInformationRequiredByDefault || this.payrollEmployeeRequiresBankInfo()) {
            this.bankingInformationRequired = true;
            this.changeDetector.detectChanges();
            return;
        }

        if (this.bankAccount) {
            this.bankingInformationRequired = Boolean(
                this.bankAccount.accountNumber || this.bankAccount.branchNumber || this.bankAccount.institutionNumber
            );
        }

        this.changeDetector.detectChanges();
    }

    onPaymentMethodChange(): void {
        this.bankingInformationRequired = this.employee.paymentMethod === 'direct deposit';
        this.changeDetector.detectChanges();
    }

    disablePaymentMethod(): boolean {
        return (
            this.employee.activeOnPayroll() &&
            this.bankAccount.id !== null &&
            this.employee.getOriginalAttribute('paymentMethod') === 'direct deposit'
        );
    }

    showDirectDepositWarning(): boolean {
        return (
            this.employee.activeOnPayroll() &&
            this.bankAccount.id === null &&
            this.employee.getOriginalAttribute('paymentMethod') !== 'direct deposit' &&
            this.employee.paymentMethod === 'direct deposit'
        );
    }

    payrollEmployeeRequiresBankInfo(): boolean {
        return this.employee.activeOnPayroll() && this.employee.paymentMethod === 'direct deposit';
    }

    disableBankInformation(): boolean {
        return this.employee.paymentMethod !== 'direct deposit' && this.bankAccount.id === null;
    }
}
