import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
    BulkSelectOnboardingRemindersComponent,
    BulkSelectRolesComponent,
    BulkSelectTrainingProgramsComponent,
    ConfirmationDialogComponent,
    DeprecatedModalComponent,
    DeprecatedModalFormComponent,
    PdfViewerComponent,
    SelectBenefitPlanDialogComponent,
} from '@app/components';
import { BulkSelectCustomFieldDialogComponent } from '@app/components/dialogs/bulk-select-custom-field-dialog/bulk-select-custom-field-dialog.component';
import { SelectWorkScheduleDialogComponent } from '@app/components/dialogs/select-work-schedule-dialog/select-work-schedule-dialog.component';
import { AnalyticEvents, FeatureFlag, SocketEventTypes } from '@app/enums';
import { EmployeeOnboardingBenefitSelection } from '@app/interfaces';
import { BenefitDivision } from '@app/models/benefits/benefit-division.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { Task } from '@app/models/tasks/task.model';
import { TrainingProgram } from '@app/models/training/training-program.model';
import { SimplyBenefitsOnboardingData } from '@app/modules/benefits/interfaces/simply-benefits-onboarding-data.interface';
import { TimeTrackingSettingsService } from '@app/modules/time-tracking/services/time-tracking-settings.service';
import { AnalyticService, AuthService, NotifyService, SocketService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { Translatable } from '@app/types/translatable.type';
import { RoleNameDisplayOptions } from '@app/types/translatables/role-display.options';
import { BaseForm } from '@forms/base.form';
import { OnboardingTimeOffPoliciesForm } from '@forms/employees/components';
import { SelectedPolicy } from '@forms/employees/components/onboarding-time-off-policies/onboarding-time-off-policies.form';
import { Role } from '@models/account/role.model';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { Employee } from '@models/employee/employee.model';
import { OnboardingReminder } from '@models/employee/onboarding-reminder.model';
import { DataField } from '@models/settings/data-field.model';
import { WorkSchedule } from '@models/time-off-v3/works-schedule.model';
import { Project } from '@models/time-tracking/project.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-employees-form-hire-onboarding',
    templateUrl: './onboarding.form.html',
    styleUrls: ['./onboarding.style.scss'],
})
export class OnboardingForm extends BaseForm implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('selectCustomFields', { static: true }) selectCustomFields: BulkSelectCustomFieldDialogComponent;
    @ViewChild('selectReminders', { static: true }) selectReminders: BulkSelectOnboardingRemindersComponent;
    @ViewChild('selectWorkSchedule', { static: true }) selectWorkSchedule: SelectWorkScheduleDialogComponent;
    @ViewChild('selectRoles', { static: true }) selectRoles: BulkSelectRolesComponent;
    @ViewChild('selectTrainingPrograms', { static: true }) selectTrainingPrograms: BulkSelectTrainingProgramsComponent;
    @ViewChild('selectBenefitPlan', { static: true }) selectBenefitPlan: SelectBenefitPlanDialogComponent;
    @ViewChild('pdfPreviewModal', { static: true }) pdfPreviewModal: DeprecatedModalComponent;
    @ViewChild('pdfViewer', { static: true }) pdfPreview: PdfViewerComponent;
    @ViewChild('selectTimeOffPolicies') selectTimeOffPolicies: DeprecatedModalFormComponent;
    @ViewChild('selectTimeOffPoliciesForm') selectTimeOffPoliciesForm: OnboardingTimeOffPoliciesForm;
    @ViewChild('applyTasksModal', { static: true }) applyTasksModal: DeprecatedModalComponent;
    @ViewChild('timeTrackingSettingsConfirmation', { static: true })
    timeTrackingSettingsConfirmation: ConfirmationDialogComponent;
    @Input() taskTemplates: TaskTemplate[] = [];
    @Input() customFields: DataField[] = [];
    @Input() reminders: OnboardingReminder[] = [];
    @Input() workSchedule: WorkSchedule | null = null;
    @Input() timeOffPolicies: SelectedPolicy[] = [];
    @Input() roles: Role[] = [];
    @Input() trainingPrograms: TrainingProgram[] = [];
    @Input() benefitPlan: BenefitPlan | null = null;
    @Input() benefitDivision: BenefitDivision | null = null;
    @Input() isWaitingPeriodWaved = false;
    @Input() simplyBenefitsData: SimplyBenefitsOnboardingData | null = null;
    @Input() selectedBenefitLabel: string | null = null;
    @Input() employee: Employee;
    @Input() addToTimeTracking = false;
    @Input() addToTimeTrackingPayrollIntegration = false;
    @Input() timeTrackingProjects: Project[] = [];
    @Output() taskTemplatesChange: EventEmitter<TaskTemplate[]> = new EventEmitter<TaskTemplate[]>();
    @Output() customFieldsChange: EventEmitter<DataField[]> = new EventEmitter<DataField[]>();
    @Output() remindersChange: EventEmitter<OnboardingReminder[]> = new EventEmitter<OnboardingReminder[]>();
    @Output() workScheduleChange: EventEmitter<WorkSchedule> = new EventEmitter<WorkSchedule>();
    @Output() timeOffPoliciesChange: EventEmitter<SelectedPolicy[]> = new EventEmitter<SelectedPolicy[]>();
    @Output() rolesChange: EventEmitter<Role[]> = new EventEmitter<Role[]>();
    @Output() trainingProgramsChange: EventEmitter<TrainingProgram[]> = new EventEmitter<TrainingProgram[]>();
    @Output() benefitPlanChange: EventEmitter<BenefitPlan> = new EventEmitter<BenefitPlan>();
    @Output() benefitDivisionChange: EventEmitter<BenefitDivision> = new EventEmitter<BenefitDivision>();
    @Output() isWaitingPeriodWavedChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() addToTimeTrackingChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() addToTimeTrackingPayrollIntegrationChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() timeTrackingProjectsChange: EventEmitter<Project[]> = new EventEmitter<Project[]>();
    @Output() simplyBenefitsDataChange: EventEmitter<SimplyBenefitsOnboardingData> =
        new EventEmitter<SimplyBenefitsOnboardingData>();
    @Output() selectedBenefitLabelChange: EventEmitter<string | null> = new EventEmitter<string | null>();

    isLoading = true;

    showWorkSchedules = false;
    showOnboardingTasks = false;
    showCreateTaskTemplateModal = false;

    showBenefitsDialog = false;
    clearSimplyBenefitsDataBeforeOpen = false;

    /**
     * Whether to show the time tracking settings section.
     */
    showTimeTracking = false;

    autoMatchedTaskTemplates: TaskTemplate[] = [];
    otherTaskTemplates: TaskTemplate[] = [];

    /**
     * All projects in the company, loaded on demand.
     */
    allProjects: Project[] = [];

    /**
     * Projects that are currently selected in the project selector.
     */
    selectedProjects: Project[] = [];

    /**
     * Whether the user has selected the "Add to time tracking" checkbox.
     */
    selectedAddToTimeTracking = false;

    selectedAddToTimeTrackingPayrollIntegration = false;

    companyChannelSubscription: Subscription;

    roleDisplayOptions = RoleNameDisplayOptions;
    hasSimplyBenefitsFlag = false;
    companyHasSimplyBenefits = false;
    benefitsHeader: Translatable = 'forms.applicant-tracker.onboarding.benefitPlan';
    benefitsDescription: Translatable = 'forms.applicant-tracker.onboarding.assignABenefitPlanToThisEmployee';
    companyIsOnTimeTrackingPayrollIntegration = false;

    constructor(
        protected auth: AuthService,
        protected analyticService: AnalyticService,
        private featureService: FeatureService,
        private socket: SocketService,
        private notify: NotifyService,
        private timeTrackingSettingsService: TimeTrackingSettingsService
    ) {
        super();

        this.companyChannelSubscription = this.socket
            .getCompanyChannel(SocketEventTypes.newTaskTemplateCreated)
            .subscribe(() => {
                this.setAutoMatchedTaskTemplates();
                this.setOtherTaskTemplates();
            });
    }

    async ngOnInit(): Promise<void> {
        await this.setShowWorkSchedules();

        // set showOnboardingTasks based on task permission
        this.showOnboardingTasks = this.auth.can(Task.managePermission);

        // Simply Benefits
        this.hasSimplyBenefitsFlag = await this.featureService.has(FeatureFlag.simplyBenefits);
        if (this.hasSimplyBenefitsFlag && this.auth.company?.simplyBenefitsAccountId) {
            this.companyHasSimplyBenefits = true;
            this.benefitsHeader = 'forms.applicant-tracker.onboarding.benefitsHeader';
            this.benefitsDescription = 'forms.applicant-tracker.onboarding.assignThisEmployeeToBenefitClass';
        }

        this.setTimeTrackingVisibility();

        this.isLoading = false;
    }

    ngOnDestroy(): void {
        this.companyChannelSubscription.unsubscribe();
    }

    async onAddTaskTemplate(): Promise<void> {
        await this.setAutoMatchedTaskTemplates();
        await this.setOtherTaskTemplates();
        await this.displayTasksModal();
    }

    onApplyTaskTemplates(selectedTaskTemplates: TaskTemplate[]): void {
        this.taskTemplates = [...selectedTaskTemplates];
        this.taskTemplatesChange.emit(this.taskTemplates);
    }

    onRemoveTaskTemplate(selectedTaskTemplate: TaskTemplate): void {
        this.taskTemplates = this.taskTemplates.filter(
            (taskTemplate: TaskTemplate) => taskTemplate.id !== selectedTaskTemplate.id
        );
        this.taskTemplatesChange.emit(this.taskTemplates);
    }

    onSelectCustomFields(): void {
        this.selectCustomFields.show(this.customFields).then((customFields: DataField[]) => {
            if (customFields) {
                this.customFields = customFields;
                this.customFieldsChange.emit(customFields);
                this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddCustomFields);
            }
        });
    }

    customFieldsFiltersAdded(): void {
        this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeCustomFieldFiltersAdded);
    }

    onRemoveCustomField(customField: DataField): void {
        this.customFields = this.customFields.filter((c: DataField) => c.id !== customField.id);
        this.customFieldsChange.emit(this.customFields);
    }

    onSelectOnboardingReminders(): void {
        this.selectReminders.show(this.reminders).then((reminders: OnboardingReminder[]) => {
            if (reminders) {
                this.reminders = reminders;
                this.remindersChange.emit(this.reminders);
                this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddReminders);
            }
        });
    }

    onRemoveOnboardingReminder(reminder: OnboardingReminder): void {
        this.reminders = this.reminders.filter((r: OnboardingReminder) => r.id !== reminder.id);
        this.remindersChange.emit(this.reminders);
    }

    async onSelectWorkSchedule(): Promise<void> {
        try {
            const workScheduleSelection = await this.selectWorkSchedule.show(this.workSchedule);

            if (!workScheduleSelection) {
                return;
            }

            const workSchedule = workScheduleSelection;

            this.workSchedule = workSchedule;
            this.analyticService.trackEvent(
                AnalyticEvents.PeopleDirectoryCreateEmployeeHireAssignWorkScheduleClickSelectToSave
            );
            this.workScheduleChange.emit(workSchedule);
        } catch (e) {
            this.notify.error(e);
        }
    }

    onRemoveWorkSchedule(): void {
        this.workSchedule = null;
        this.workScheduleChange.emit(this.workSchedule);
    }

    onSelectTimeOffPolicies(): void {
        this.selectTimeOffPolicies.show();
    }

    onSelectRoles(): void {
        this.selectRoles
            .assignableBy(this.auth.role)
            .show(this.roles)
            .then((roles: Role[]) => {
                if (roles) {
                    this.roles = roles;
                    this.rolesChange.emit(roles);
                    this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddRolesAndPermissions);
                }
            });
    }

    onRemoveRole(role: Role): void {
        this.roles = this.roles.filter((l: Role) => l.id !== role.id);
        this.rolesChange.emit(this.roles);
    }

    async showLegacyBenefitsDialog(): Promise<void> {
        const benefitSelection: EmployeeOnboardingBenefitSelection = await this.selectBenefitPlan.show({
            benefitPlan: this.benefitPlan,
        });

        if (!benefitSelection) {
            return;
        }

        const { benefitPlan, benefitDivision, isWaitingPeriodWaved } = benefitSelection;

        this.benefitPlan = benefitPlan;
        this.benefitDivision = benefitDivision;
        this.isWaitingPeriodWaved = isWaitingPeriodWaved;
        this.selectedBenefitLabel = this.benefitPlan?.name ?? null;
        this.benefitPlanChange.emit(benefitPlan);
        this.benefitDivisionChange.emit(benefitDivision);
        this.isWaitingPeriodWavedChange.emit(isWaitingPeriodWaved);
        this.selectedBenefitLabelChange.emit(this.selectedBenefitLabel);
        this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddBenefitPlan);
    }

    showBenefitDataSelectionDialog(): void {
        if (this.companyHasSimplyBenefits) {
            //This will show the new Simply Benefits modal
            this.showBenefitsDialog = true;
            return;
        }

        this.showLegacyBenefitsDialog();
    }

    async onBenefitDataChange(benefitData: SimplyBenefitsOnboardingData): Promise<void> {
        this.clearSimplyBenefitsDataBeforeOpen = false;
        this.simplyBenefitsData = benefitData;
        this.selectedBenefitLabel = this.simplyBenefitsData?.class?.title ?? null;
        this.simplyBenefitsDataChange.emit(benefitData);
        this.selectedBenefitLabelChange.emit(this.selectedBenefitLabel);
    }

    onRemoveSimplyBenefitsData(benefitData: SimplyBenefitsOnboardingData): void {
        benefitData = null;
        this.simplyBenefitsData = null;
        this.clearSimplyBenefitsDataBeforeOpen = true;
        this.selectedBenefitLabel = null;
        this.simplyBenefitsDataChange.emit(benefitData);
        this.selectedBenefitLabelChange.emit(this.selectedBenefitLabel);
    }

    onRemoveBenefitPlan(benefitPlan: BenefitPlan): void {
        benefitPlan = null;
        this.benefitDivision = null;
        this.isWaitingPeriodWaved = false;
        this.selectedBenefitLabel = null;
        this.benefitPlanChange.emit(benefitPlan);
        this.benefitDivisionChange.emit(this.benefitDivision);
        this.isWaitingPeriodWavedChange.emit(this.isWaitingPeriodWaved);
        this.selectedBenefitLabelChange.emit(this.selectedBenefitLabel);
    }

    onSelectTrainingPrograms(): void {
        this.selectTrainingPrograms.show(this.trainingPrograms).then((trainingPrograms: TrainingProgram[]) => {
            if (trainingPrograms) {
                this.trainingPrograms = trainingPrograms;
                this.trainingProgramsChange.emit(trainingPrograms);
                this.analyticService.trackEvent(AnalyticEvents.CreateEmployeeAddTrainingPrograms);
            }
        });
    }

    onRemoveTrainingPrograms(trainingProgram: TrainingProgram): void {
        this.trainingPrograms = this.trainingPrograms.filter((item: TrainingProgram) => item.id !== trainingProgram.id);
        this.trainingProgramsChange.emit(this.trainingPrograms);
    }

    onSaveTimeOffPolicies(): void {
        this.timeOffPolicies = this.selectTimeOffPoliciesForm.selectedPolicies;
        this.timeOffPoliciesChange.emit(this.timeOffPolicies);
    }

    onRemoveTimeOffPolicy(timeOffPolicy: SelectedPolicy): void {
        this.timeOffPolicies = this.timeOffPolicies.filter(
            (policy: SelectedPolicy) =>
                policy.timeOffPolicy && policy.timeOffPolicy.id !== timeOffPolicy.timeOffPolicy?.id
        );

        this.timeOffPoliciesChange.emit(this.timeOffPolicies);
    }

    hasPolicySelected(): boolean {
        return !!this.getTimeOffPolicies()?.length;
    }

    getTimeOffPolicies(): SelectedPolicy[] {
        return this.timeOffPolicies?.filter((selectedPolicy) => !!selectedPolicy?.timeOffPolicy);
    }

    /**
     * onSelectTimeTracking triggers a modal where the user can select time tracking projects
     * to be assigned to the employee. If the user clicks "Save", the selected projects are
     * assigned to the employee.
     */
    async onSelectTimeTracking(): Promise<void> {
        await this.setAllProjects();

        this.selectedProjects = [...this.timeTrackingProjects];
        this.selectedAddToTimeTracking = this.addToTimeTracking;
        this.selectedAddToTimeTrackingPayrollIntegration = this.addToTimeTrackingPayrollIntegration;

        const shouldUpdate = await this.timeTrackingSettingsConfirmation.open();

        if (shouldUpdate) {
            this.addToTimeTracking = this.selectedAddToTimeTracking;
            this.addToTimeTrackingChange.emit(this.addToTimeTracking);
            this.addToTimeTrackingPayrollIntegrationChange.emit(this.selectedAddToTimeTrackingPayrollIntegration);

            // if they've decided they no longer want to add to time tracking, clear the selected projects
            if (!this.addToTimeTracking) {
                this.selectedProjects = [];
            }

            this.timeTrackingProjects = [...this.selectedProjects];
            this.timeTrackingProjectsChange.emit(this.timeTrackingProjects);
        }
    }

    onRemoveTimeTrackingProject(projectToRemove: Project): void {
        this.timeTrackingProjects = this.timeTrackingProjects.filter((p: Project): boolean => p.isNot(projectToRemove));
        this.timeTrackingProjectsChange.emit(this.timeTrackingProjects);
    }

    compareProjects(project1: Project | undefined, project2: Project | undefined): boolean {
        return project1?.id === project2?.id;
    }

    /**
     * onSelectAllProjects is sort of a hack.
     * We're using the "select all" checkbox to toggle between selecting all projects and selecting none.
     * But the "select all" checkbox is not actually an option, even though it's within the select.
     */
    onSelectAllProjects(): void {
        if (this.selectedProjects.length === this.allProjects.length) {
            this.selectedProjects = [];
            return;
        }

        this.selectedProjects = [...this.allProjects];
    }

    private async setShowWorkSchedules(): Promise<void> {
        this.showWorkSchedules = !!this.auth.company.modules.find((module) => module.name === 'Time Off');
    }

    private async setAutoMatchedTaskTemplates(): Promise<void> {
        const [autoMatchedTaskTemplates] = await TaskTemplate.where('task_type', 'onboarding')
            .where('filterCriteria', this.getFilterCriteria())
            .all();
        this.autoMatchedTaskTemplates = autoMatchedTaskTemplates;
    }

    private async setOtherTaskTemplates(): Promise<void> {
        const [otherTaskTemplates] = await TaskTemplate.where('task_type', 'onboarding')
            .where('filterCriteriaNonMatching', this.getFilterCriteria())
            .all();
        this.otherTaskTemplates = otherTaskTemplates;
    }

    private async displayTasksModal(): Promise<void> {
        if (!this.autoMatchedTaskTemplates.length && !this.otherTaskTemplates.length) {
            this.showCreateTaskTemplateModal = true;
            return;
        }

        this.showCreateTaskTemplateModal = false;
        this.applyTasksModal.show();
    }

    private getFilterCriteria(): object {
        return {
            departmentId: this.employee?.department?.id,
            jobId: this.employee?.job?.id,
            officeId: this.employee?.office?.id,
            employmentTypeId: this.employee?.employmentTypeId,
        };
    }

    private async setAllProjects(): Promise<void> {
        const [projects] = await Project.where('is_archived', 0).orderBy('name', 'asc').get();
        this.allProjects = projects;
    }
    private async setTimeTrackingVisibility(): Promise<void> {
        this.showTimeTracking = this.auth.isAssignedTimeTrackingAdmin();

        if (!this.showTimeTracking) {
            return;
        }

        const settings = await this.timeTrackingSettingsService.get();

        this.companyIsOnTimeTrackingPayrollIntegration =
            this.auth.company.isPayrollSyncEnabled && Boolean(settings.payrollIntegration);
    }
}
