import { Component, Input } from '@angular/core';
import { BaseForm } from '@forms/base.form';
import { TimeOffType } from '@models/time-off-v3/time-off-type.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffPolicy } from '@models/time-off-v3/time-off-policy.model';

export interface SelectedPolicy {
    typeId: number;
    timeOffPolicy?: TimeOffPolicy;
    effectiveAt: Date;
}

@Component({
    selector: 'app-form-onboarding-time-off-policies',
    templateUrl: './onboarding-time-off-policies.template.html',
    styleUrls: ['./onboarding-time-off-policies.style.scss'],
})
export class OnboardingTimeOffPoliciesForm extends BaseForm {
    @Input() employee: Employee;
    @Input() policies: SelectedPolicy[] = [];
    timeOffTypes: TimeOffType[] = [];
    selectedPolicies: SelectedPolicy[] = [];

    beforeShow(): void {
        TimeOffType.with('timeOffPolicies')
            .all()
            .then(
                ([timeOffTypes]) =>
                    (this.timeOffTypes = timeOffTypes.filter(
                        (timeOffType: TimeOffType) => !!timeOffType.timeOffPolicies?.length
                    ))
            )
            .then(() => this.prePopulateSelectedPolicies());
    }

    setTimeOffPolicy(timeOffType: TimeOffType, policyId: number): void {
        this.findSelectedPolicy(timeOffType).timeOffPolicy = this.timeOffTypes
            .find((type) => type.id === timeOffType.id)
            .timeOffPolicies.find((policy) => policy.id === policyId);
    }

    findSelectedPolicy(timeOffType: TimeOffType): SelectedPolicy {
        return this.selectedPolicies.find((selectedPolicy) => selectedPolicy.typeId === timeOffType.id);
    }

    private prePopulateSelectedPolicies(): void {
        this.selectedPolicies = [];

        this.timeOffTypes.forEach((timeOffType) => {
            const policySelected = this.policySelected(timeOffType);

            if (policySelected) {
                this.selectedPolicies.push(policySelected);
                return;
            }

            this.selectedPolicies.push({
                typeId: timeOffType.id,
                effectiveAt: this.employee.hiredAt,
            });
        });
    }

    private policySelected(timeOffType: TimeOffType): SelectedPolicy {
        return this.policies?.find((p) => p.typeId === timeOffType.id);
    }
}
