<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="payrollEmployee">
        <div class="app-grid">
            <mat-form-field class="columns-12">
                <mat-label>Additional tax to be deducted (per period)</mat-label>
                <input
                    type="number"
                    placeholder="Input amount"
                    required
                    min="0"
                    [(ngModel)]="payrollEmployee.taxInformation.additionalTaxDeducted"
                    name="additionalTax"
                    #additionalTax="ngModel"
                    matInput
                />
                <mat-error *ngIf="additionalTax.invalid">{{ additionalTax.errors | formErrors }}</mat-error>
            </mat-form-field>

            <div style="color: rgba(0, 0, 0, 0.5)" class="columns-8 small-columns-12">
                Enter the per period amount of additional tax you would like to deduct from this employee
            </div>
        </div>
    </ng-container>
</form>
