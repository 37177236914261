import { Component, Input } from '@angular/core';
import { maxBornOnDate, minBornOnDate } from '@app/constants/dates';
import { FeatureFlag } from '@app/enums';
import { makePhoneNumberMask } from '@app/functions';
import { TextMask } from '@app/interfaces';
import { Employee } from '@app/models/employee/employee.model';
import { FeatureService } from '@app/services/feature.service';
import { BaseForm } from '@forms/base.form';
import { Account } from '@models/account/account.model';

@Component({
    selector: 'app-form-personal-information',
    templateUrl: './personal-information.form.html',
    styleUrls: ['./personal-information.style.scss'],
})
export class PersonalInformationForm extends BaseForm {
    @Input() account: Account;
    @Input() employee: Employee;
    editSinPermission = Employee.sinPermission.some('update');
    editBornOnPermission = Employee.bornOnPermission.some('update');
    phoneNumberMask: TextMask = makePhoneNumberMask();
    minBornOnDate = minBornOnDate;
    maxBornOnDate = maxBornOnDate;

    updateLegalNamePermission: string[] = Account.legalNamePermission.some('update');
    updateLegalSexPermission: string[] = Employee.legalSexPermission.some('update');
    shouldShowBirthdayVisibility = false;

    constructor(private featureService: FeatureService) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.shouldShowBirthdayVisibility = await this.featureService.has(FeatureFlag.birthdayVisibility);
    }

    submit(): void {
        super.submit();
    }
}
