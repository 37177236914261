<form #form="ngForm">
    <ng-container *ngIf="department">
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'employees.department.name' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    required
                    name="name"
                    #title="ngModel"
                    [(ngModel)]="department.name"
                    placeholder="{{ 'employees.department.marketing' | translate }}"
                />
                <mat-error *ngIf="title.invalid">{{ title.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'employees.department.color' | translate }}</mat-label>
                <ui-color-picker name="color" placeholder="#000000" [(ngModel)]="department.color"></ui-color-picker>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'employees.department.description' | translate }}</mat-label>
                <ui-rich-text
                    #description="ngModel"
                    [(ngModel)]="department.description"
                    name="description"
                    height="175px"
                ></ui-rich-text>
                <mat-error *ngIf="description.invalid">{{ description.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
