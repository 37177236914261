import { Model } from '../../core/base.model';

import { SimpleEntityActions } from '@app/classes';

/**
	@deprecated Please use [TaxCredit](./tax-credit.model.ts) instead
*/
export class LegacyTaxCredit extends Model {
    get employeeTaxInformationId(): number {
        return this._attributes['employeeTaxInformationId'];
    }
    set employeeTaxInformationId(val: number) {
        this._attributes['employeeTaxInformationId'] = val;
    }

    get jurisdiction(): string {
        return this._attributes['jurisdiction'];
    }
    set jurisdiction(val: string) {
        this._attributes['jurisdiction'] = val;
    }

    get name(): string {
        return this._attributes['name'];
    }
    set name(val: string) {
        this._attributes['name'] = val;
    }

    get amount(): number {
        return this._attributes['amount'];
    }
    set amount(val: number) {
        this._attributes['amount'] = val;
    }

    get date(): Date {
        return this._attributes['date'];
    }
    set date(val: Date) {
        this._attributes['date'] = val;
    }
    protected static _resource = 'employeeManagement/employees/:employee/taxInformation/:taxInformation/taxCredits';

    protected static _version = 'v2';

    protected static _serializeAttributes = ['employeeTaxInformationId', 'jurisdiction', 'name', 'amount', 'date'];

    static permission = new SimpleEntityActions('taxCredit');
}
