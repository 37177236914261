<form #form="ngForm">
    <ng-container *ngIf="employee">
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.primaryContactName' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="{{ 'account.maleFullNamePlaceHolder' | translate }}"
                    #ecPrimaryName="ngModel"
                    name="ecPrimaryName"
                    [(ngModel)]="employee.ecPrimaryName"
                    matInput
                />
                <mat-error *ngIf="ecPrimaryName.invalid">{{ ecPrimaryName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.primaryContactRelation' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="{{ 'account.husband' | translate }}"
                    #ecPrimaryRelation="ngModel"
                    name="ecPrimaryRelation"
                    [(ngModel)]="employee.ecPrimaryRelation"
                    matInput
                />
                <mat-error *ngIf="ecPrimaryRelation.invalid">{{ ecPrimaryRelation.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.primaryContactPhone' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="416-405-4039"
                    #ecPrimaryPhone="ngModel"
                    name="ecPrimaryPhone"
                    [(ngModel)]="employee.ecPrimaryPhone"
                    matInput
                />
                <mat-error *ngIf="ecPrimaryPhone.invalid">{{ ecPrimaryPhone.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.secondaryContactName' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="{{ 'account.femaleFullNamePlaceHolder' | translate }}"
                    #ecSecondaryName="ngModel"
                    name="ecSecondaryName"
                    [(ngModel)]="employee.ecSecondaryName"
                    matInput
                />
                <mat-error *ngIf="ecSecondaryName.invalid">{{ ecSecondaryName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.secondaryContactRelation' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="{{ 'account.sister' | translate }}"
                    #ecSecondaryRelation="ngModel"
                    name="ecSecondaryRelation"
                    [(ngModel)]="employee.ecSecondaryRelation"
                    matInput
                />
                <mat-error *ngIf="ecSecondaryRelation.invalid">{{ ecSecondaryRelation.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.secondaryContactPhone' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="416-405-4039"
                    #ecSecondaryPhone="ngModel"
                    name="ecSecondaryPhone"
                    [(ngModel)]="employee.ecSecondaryPhone"
                    matInput
                />
                <mat-error *ngIf="ecSecondaryPhone.invalid">{{ ecSecondaryPhone.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
