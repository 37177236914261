<form #form="ngForm">
    <div class="app-grid margin-bottom-4">
        <h2>{{ 'forms.employees-leaveOfAbsence.leaveOfAbsenceDetails' | appTranslate }}</h2>
    </div>

    <div class="app-grid margin-bottom-3">
        <p class="font-medium">{{ 'Employee' | appTranslate }}</p>
        <p>{{ employee.fullName }}</p>
    </div>

    <div class="app-grid margin-bottom-3">
        <div class="columns-3">
            <p class="font-medium">{{ 'forms.employees-leaveOfAbsence.startDate' | appTranslate }}</p>
            <p>{{ leaveOfAbsence.start | appDate: 'yyyy-MM-dd' }}</p>
        </div>

        <div class="columns-3">
            <p class="font-medium">{{ 'forms.employees-leaveOfAbsence.endDate' | appTranslate }}</p>
            <p>{{ leaveOfAbsence?.end ? (leaveOfAbsence.end | appDate: 'yyyy-MM-dd') : 'Unknown' }}</p>
        </div>
    </div>

    <div class="app-grid margin-bottom-3">
        <p class="font-medium">{{ 'forms.employees-leaveOfAbsence.leaveType' | appTranslate }}</p>
        <p>
            {{
                leaveOfAbsenceDisplayMeta[leaveOfAbsence.leaveOfAbsenceTypeId]
                    | appTranslateOptions: leaveOfAbsenceTypesDisplayOptions
            }}
        </p>
    </div>

    <div class="app-grid margin-bottom-3">
        <p class="font-medium">{{ 'forms.employees-leaveOfAbsence.description' | appTranslate }}</p>
        <p>{{ leaveOfAbsence.description }}</p>
    </div>

    <div *ngIf="selectedTaskTemplates.length" class="app-grid margin-bottom-3">
        <h2 class="margin-bottom-0">{{ 'forms.employees-leaveOfAbsence.tasks' | appTranslate }}</h2>
        <div class="item-list">
            <app-common-label-item *ngFor="let taskTemplate of selectedTaskTemplates" [label]="taskTemplate.name">
            </app-common-label-item>
        </div>
    </div>
</form>
