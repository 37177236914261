import { Component } from '@angular/core';
import { CompanyAdditionalIncome } from '@app/models/payroll/company-additional-income.model';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-add-additional-income',
    templateUrl: './add-additional-income.template.html',
})
export class AddAdditionalIncomeForm extends BaseForm {
    companyAdditionalIncomes: CompanyAdditionalIncome[] = [];
    companyAdditionalIncome: CompanyAdditionalIncome | null = null;
    isReseting = false;

    reset(): void {
        this.companyAdditionalIncome = null;
        this.isReseting = true;
        setTimeout(() => (this.isReseting = false), 0);
        super.reset();
    }
}
