<app-deprecated-modal-form
    #departmentModal
    headerText="{{ 'applicant-tracker.createNewDepartment' | appTranslate }}"
    [form]="departmentForm"
    (save)="createAndSetDepartment(departmentForm)"
    (cancel)="employee.department = null"
>
    <app-department-form #departmentForm></app-department-form>
</app-deprecated-modal-form>

<app-deprecated-modal-form
    #positionModal
    headerText="{{ 'applicant-tracker.createNewPosition' | appTranslate }}"
    [form]="positionForm"
    (save)="createAndSetPosition(positionForm)"
    (cancel)="employee.job = null"
>
    <app-position-form #positionForm></app-position-form>
</app-deprecated-modal-form>

<app-deprecated-modal-form
    #officeModal
    headerText="{{ 'applicant-tracker.createNewOffice' | appTranslate }}"
    [form]="officeForm"
    (save)="createAndSetOffice(officeForm)"
    (cancel)="employee.office = null"
>
    <app-office-form #officeForm></app-office-form>
</app-deprecated-modal-form>

<!-- Show this modal if the email address already belongs to an existing candidate with a pending or signed offer letter -->
<ui-dialog
    promptDialog
    #candidateEmailValidationModal
    [header]="'applicant-tracker.candidateEmailFound'"
    [open]="openCandidateEmailValidationModal"
    (closing)="openCandidateEmailValidationModal = false; onCloseCandidateEmailValidationModal()"
    [primaryButtonLabel]="candidateEmailValidationModalPrimaryButtonLabel"
    (primaryButtonPressed)="onViewCandidate()"
    [secondaryButtonLabel]="'close'"
    [secondaryButtonType]="'ghost'"
    (secondaryButtonPressed)="openCandidateEmailValidationModal = false; onCloseCandidateEmailValidationModal()"
>
    <p>{{ candidateEmailValidationModalDescription | appTranslate }}</p>
</ui-dialog>

<!--Loader-->
<div *ngIf="isLoading" class="ui active inverted dimmer">
    <div class="ui loader"></div>
</div>

<form #form="ngForm" style="max-width: 900px" (ngSubmit)="onSubmit()">
    <app-segment-header title="{{ 'applicant-tracker.accountInformation' | appTranslate }}"></app-segment-header>
    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.firstName' | appTranslate }}</mat-label>
            <input
                type="text"
                name="legalFirstName"
                required
                #firstName="ngModel"
                matInput
                placeholder="{{ 'forms.applicant-tracker.hire-flow.egAlex' | appTranslate }}"
                [(ngModel)]="account.legalFirstName"
            />

            <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.lastName' | appTranslate }}</mat-label>
            <input
                type="text"
                name="legalLastName"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.egSmith' | appTranslate }}"
                required
                #legalLastName="ngModel"
                matInput
                [(ngModel)]="account.legalLastName"
            />

            <mat-error *ngIf="legalLastName.invalid">{{ legalLastName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.email' | appTranslate }}</mat-label>
            <input
                type="text"
                placeholder="alex@domain.com"
                name="email"
                required
                #email="ngModel"
                [ngModel]="account.email"
                (ngModelChange)="removeWhiteSpacesOnEmail($event)"
                matInput
                (blur)="onBlur()"
                emailValidator
                candidateEmailValidator
                (candidateId)="setCandidateId($event)"
            />

            <mat-error *ngIf="email.invalid">{{ email.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.applicant-tracker.hire-flow.office' | appTranslate }}</mat-label>
            <mat-select
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectOffice' | appTranslate }}"
                required
                name="officeSelect"
                #office="ngModel"
                [compareWith]="compareWith"
                [(ngModel)]="employee.office"
            >
                <mat-option *permission="createOfficePermission" (click)="officeModal.show()"
                    ><span class="add-option">{{
                        'forms.applicant-tracker.hire-flow.addNewOffice' | appTranslate
                    }}</span></mat-option
                >
                <mat-option *ngFor="let office of offices" [value]="office">{{ office.name }}</mat-option>
            </mat-select>

            <mat-error *ngIf="office.invalid">{{ office.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.hireDate' | appTranslate }}</mat-label>
            <input
                required
                hiredAtValidator
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectADate' | appTranslate }}"
                matInput
                name="hiredAt"
                #hiredAt="ngModel"
                [(ngModel)]="employee.hiredAt"
                [matDatepicker]="hiredAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="hiredAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #hiredAtPicker></mat-datepicker>

            <mat-error *ngIf="hiredAt.invalid">{{ hiredAt.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.additionalTerms' | appTranslate }}</mat-label>
            <input
                type="text"
                matInput
                name="additionalterms"
                [(ngModel)]="employee.additionalTerms"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.egMovingBonus' | appTranslate }}"
                [maxLength]="500"
            />
        </mat-form-field>

        <mat-form-field *ngIf="employee.offerAcceptedDate" class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.offerAcceptedDate' | appTranslate }}</mat-label>
            <input
                disabled
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectADate' | appTranslate }}"
                matInput
                name="offerAcceptedDate"
                #offerAcceptedDate="ngModel"
                [(ngModel)]="employee.offerAcceptedDate"
                [matDatepicker]="offerAcceptedDatePicker"
            />
            <mat-datepicker-toggle matSuffix [for]="offerAcceptedDatePicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #offerAcceptedDatePicker></mat-datepicker>

            <mat-error *ngIf="offerAcceptedDate.invalid">{{ offerAcceptedDate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <!-- Compensation -->
    <app-segment-header
        title="{{ 'forms.applicant-tracker.hire-flow.compensation' | appTranslate }}"
    ></app-segment-header>
    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label appRemoveAriaOwns>{{
                'forms.applicant-tracker.hire-flow.compensationType' | appTranslate
            }}</mat-label>
            <mat-select
                name="compensationType"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectACompensationType' | appTranslate }}"
                required
                #compensationType="ngModel"
                [(ngModel)]="salary.frequency"
            >
                <mat-option
                    *ngFor="let compensationType of compensationTypes | keyvalue"
                    [value]="compensationType.key"
                    >{{ compensationType.value | appTranslateOptions: compensationTypeDisplay }}</mat-option
                >
            </mat-select>

            <mat-error *ngIf="compensationType.invalid">{{ compensationType.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ salary.frequencyLabel }}</mat-label>
            <input
                type="number"
                matInput
                [(ngModel)]="salary.rate"
                min="0"
                name="salary"
                #salaryRate="ngModel"
                required
                [placeholder]="
                    (salary.frequency === 'hour'
                        ? { key: 'forms.applicant-tracker.hire-flow.egAmount', params: { amount: 30 } }
                        : { key: 'forms.applicant-tracker.hire-flow.egAmount', params: { amount: 45000 } }
                    ) | appTranslate
                "
            />

            <mat-error *ngIf="salaryRate.invalid">{{ firstName.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label appRemoveAriaOwns>{{
                'forms.applicant-tracker.hire-flow.employmentType' | appTranslate
            }}</mat-label>
            <mat-select
                required
                #employmentType="ngModel"
                name="employmentType"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectAnEmploymentType' | appTranslate }}"
                [(ngModel)]="employee.employmentType"
                (selectionChange)="onEmploymentTypeChange($event)"
            >
                <mat-option *ngFor="let employmentType of employmentTypeValues" [value]="employmentType">{{
                    employmentType | appTranslateOptions: employmentTypeDisplay | capitalize
                }}</mat-option>
            </mat-select>

            <mat-error *ngIf="employmentType.invalid">{{ employmentType.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.hoursPerWeek' | appTranslate }}</mat-label>
            <input
                type="number"
                min="0"
                max="168"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.eg' | appTranslate }}"
                name="hoursPerWeek"
                required
                #hoursPerWeek="ngModel"
                matInput
                [(ngModel)]="salary.hoursPerWeek"
            />

            <mat-error *ngIf="hoursPerWeek.invalid">{{ hoursPerWeek.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.stockOptions' | appTranslate }}</mat-label>
            <input
                type="text"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.egCommonShares' | appTranslate }}"
                name="stockOptions"
                #stockOptions="ngModel"
                [(ngModel)]="employee.stockOptions"
                matInput
            />
        </mat-form-field>
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.vacationPayPercentage' | appTranslate }}</mat-label>
            <input
                type="text"
                [textMask]="{ mask: percentageMask }"
                placeholder="{{ 'forms.applicant-tracker.hire-flow.eg4Perc' | appTranslate }}"
                name="vacationPayPercentage"
                #vacationPayPercentage="ngModel"
                [ngModel]="salary.vacationPayPercentage"
                matInput
                (ngModelChange)="salary.vacationPayPercentage = stripNonNumeric($event); resetCursorPosition()"
            />
            <mat-error *ngIf="vacationPayPercentage.invalid">{{ vacationPayPercentage.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <app-segment-header
        title="{{ 'forms.applicant-tracker.hire-flow.employeesRole' | appTranslate }}"
    ></app-segment-header>
    <div class="app-grid">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.applicant-tracker.hire-flow.department' | appTranslate }}</mat-label>
            <mat-select
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectADepartment' | appTranslate }}"
                required
                [(ngModel)]="employee.department"
                #department="ngModel"
                [compareWith]="compareWith"
                name="department"
            >
                <mat-option *permission="createDepartmentPermission" (click)="departmentModal.show()"
                    ><span class="add-option">{{
                        'forms.applicant-tracker.hire-flow.addNewDepartment' | appTranslate
                    }}</span></mat-option
                >
                <mat-option *ngFor="let department of departments" [value]="department">{{
                    department.name
                }}</mat-option>
            </mat-select>

            <mat-error *ngIf="department.invalid">{{ department.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.applicant-tracker.hire-flow.position' | appTranslate }}</mat-label>
            <mat-select
                placeholder="{{ 'forms.applicant-tracker.hire-flow.selectAPosition' | appTranslate }}"
                required
                [(ngModel)]="employee.job"
                #position="ngModel"
                [compareWith]="compareWith"
                name="position"
            >
                <mat-option *permission="createPositionPermission" (click)="positionModal.show()"
                    ><span class="add-option">{{
                        'forms.applicant-tracker.hire-flow.addNewPosition' | appTranslate
                    }}</span></mat-option
                >
                <mat-option *ngFor="let position of jobs" [value]="position">{{ position.title }}</mat-option>
            </mat-select>

            <mat-error *ngIf="position.invalid">{{ position.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'forms.applicant-tracker.hire-flow.reportsTo' | appTranslate }}</mat-label>
            <ui-employee-autocomplete
                [disabled]="!canManageAll"
                readOnly="!canManageAll"
                name="reportsTo"
                #reportsTo="ngModel"
                [(ngModel)]="employee.manager"
                [allowNone]="true"
            ></ui-employee-autocomplete>
        </mat-form-field>
    </div>

    <ng-container *ngIf="payrollEnabled">
        <ng-container *permission="viewPayrollPermission">
            <app-segment-header
                title="{{ 'forms.applicant-tracker.hire-flow.humiPayroll' | appTranslate }}"
            ></app-segment-header>
            <mat-slide-toggle
                class="d-block margin-bottom-2"
                name="isPayrollSyncEnabled"
                [(ngModel)]="employee.isPayrollSyncEnabled"
                color="primary"
                >{{
                    'forms.applicant-tracker.hire-flow.automaticallySyncEmployeeInformationWithHumiPayroll'
                        | appTranslate
                }}</mat-slide-toggle
            >

            <mat-slide-toggle
                class="d-block only-dither-toggle-ui-if-disabled"
                name="isSinRequired"
                [(ngModel)]="employee.isSinRequired"
                color="primary"
                [disabled]="true"
                >{{
                    'forms.applicant-tracker.hire-flow.thisEmployeeIsRequiredToProvideTheirSinDuringOnboarding'
                        | appTranslate
                }}</mat-slide-toggle
            >
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!payrollEnabled">
        <app-segment-header
            title="{{ 'forms.applicant-tracker.hire-flow.employeeSin' | appTranslate }}"
        ></app-segment-header>
        <mat-slide-toggle class="d-block" name="isSinRequired" [(ngModel)]="employee.isSinRequired" color="primary">{{
            'forms.applicant-tracker.hire-flow.thisEmployeeIsRequiredToProvideTheirSinDuringOnboarding' | appTranslate
        }}</mat-slide-toggle>
    </ng-container>
</form>
