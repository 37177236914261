import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeprecatedModalComponent } from '@app/components';
import { Employee } from '@app/models/employee/employee.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { AuthService } from '@app/services/auth.service';
import { BaseForm } from '@forms/base.form';
import { Task } from '@models/tasks/task.model';

@Component({
    selector: 'app-leave-of-absence-task-form',
    templateUrl: './leave-of-absence-task.form.html',
    styleUrls: ['./leave-of-absence-information.style.scss'],
})
export class LeaveOfAbsenceTaskForm extends BaseForm implements OnInit {
    @ViewChild('applyTasksModal', { static: true }) applyTasksModal: DeprecatedModalComponent;
    @Input() employee = new Employee();
    @Input() selectedTaskTemplates: TaskTemplate[] = [];

    @Output() selectedTaskTemplatesChange: EventEmitter<TaskTemplate[]> = new EventEmitter<TaskTemplate[]>();

    autoMatchedTaskTemplates: TaskTemplate[] = [];
    otherTaskTemplates: TaskTemplate[] = [];
    tasks: Task[] = [];

    isLoading = false;
    showOffboardingTasks = false;

    constructor(protected auth: AuthService) {
        super();
    }

    ngOnInit(): void {
        // set showOnboardingTasks based on task permission
        this.showOffboardingTasks = this.auth.can(Task.managePermission);

        this.setAutoMatchedTaskTemplates();
        this.setOtherTaskTemplates();

        this.isLoading = false;
    }

    onApplyTaskTemplates(selectedTaskTemplates: TaskTemplate[]): void {
        this.selectedTaskTemplates = selectedTaskTemplates ? [...selectedTaskTemplates] : [];
        this.selectedTaskTemplatesChange.emit(this.selectedTaskTemplates);
    }

    onAddTask(): void {
        this.setAutoMatchedTaskTemplates();
        this.setOtherTaskTemplates();

        this.displayTasksModal();
    }

    onRemoveTask(removeTaskTemplate: TaskTemplate): void {
        this.selectedTaskTemplates = this.selectedTaskTemplates.filter(
            (taskTemplate: TaskTemplate) => taskTemplate.id !== removeTaskTemplate.id
        );

        this.selectedTaskTemplatesChange.emit(this.selectedTaskTemplates);
    }

    private async setAutoMatchedTaskTemplates(): Promise<void> {
        const [autoMatchedTaskTemplates] = await TaskTemplate.whereIn('taskType', ['general'])
            .where('filterMatching', this.employee.id)
            .all();
        this.autoMatchedTaskTemplates = autoMatchedTaskTemplates;
    }

    private async setOtherTaskTemplates(): Promise<void> {
        const [otherTaskTemplates] = await TaskTemplate.whereIn('taskType', ['general'])
            .where('filterNonMatching', this.employee.id)
            .all();
        this.otherTaskTemplates = otherTaskTemplates;
    }

    private async displayTasksModal(): Promise<void> {
        this.applyTasksModal.show();
    }
}
