<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="account && employee">
        <p>{{ 'forms.personalInformation.fieldsMayBeUsedFor' | translate }}</p>

        <!-- Show/Hide Legal Name based on Legal name permission -->
        <ng-container *permission="updateLegalNamePermission">
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.personalInformation.legalFirstName' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        #legalFirstName="ngModel"
                        [(ngModel)]="account.legalFirstName"
                        name="legalFirstName"
                    />
                    <mat-error *ngIf="legalFirstName.invalid">{{ legalFirstName.errors | formErrors }}</mat-error>
                </mat-form-field>
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.personalInformation.legalMiddleName' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        #legalMiddleName="ngModel"
                        [(ngModel)]="account.legalMiddleName"
                        name="legalMiddleName"
                    />
                </mat-form-field>
                <mat-form-field class="columns-4 small-columns-12">
                    <mat-label>{{ 'forms.personalInformation.legalLastName' | translate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        #legalLastName="ngModel"
                        [(ngModel)]="account.legalLastName"
                        name="legalLastName"
                    />
                    <mat-error *ngIf="legalLastName.invalid">{{ legalLastName.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
        </ng-container>

        <div class="app-grid">
            <div class="columns-6 small-columns-12">
                <ui-date-picker
                    label="{{ 'forms.personalInformation.dateOfBirth' | translate }}"
                    name="bornOn"
                    [minDate]="minBornOnDate"
                    [maxDate]="maxBornOnDate"
                    [(ngModel)]="employee.bornOn"
                    required
                ></ui-date-picker>
            </div>

            <!-- Show/Hide Legal Sex based on Legal sex permission -->
            <ng-container *permission="updateLegalSexPermission">
                <mat-form-field class="columns-5 small-columns-12">
                    <mat-label appRemoveAriaOwns>{{ 'forms.personalInformation.legalSex' | translate }}</mat-label>
                    <mat-select
                        placeholder="{{ 'forms.personalInformation.pleaseSelectAnOption' | translate }}"
                        required
                        #legalSex="ngModel"
                        name="legalSex"
                        [(ngModel)]="employee.legalSex"
                    >
                        <mat-option value="F">{{ 'forms.personalInformation.f' | translate }}</mat-option>
                        <mat-option value="M">{{ 'forms.personalInformation.m' | translate }}</mat-option>
                        <mat-option value="X">{{ 'forms.personalInformation.x' | translate }}</mat-option>
                        <mat-option value="P">{{ 'forms.personalInformation.preferNotToSay' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="legalSex.invalid">{{ legalSex.errors | formErrors }}</mat-error>
                </mat-form-field>
                <!-- Legal sex tooltip -->
                <ui-info
                    class="columns-1 small-columns-12"
                    text="{{ 'forms.personalInformation.legalSexIsRequiredFor' | translate }}"
                ></ui-info>
            </ng-container>
        </div>

        <!-- Show/Hide Birthday Privacy based on feature flag -->
        <div *ngIf="shouldShowBirthdayVisibility" class="app-grid">
            <mat-radio-group
                name="birthdayPrivacy"
                #birthdayPrivacy="ngModel"
                required
                [(ngModel)]="employee.isBirthdayPrivate"
            >
                <div class="birthday-privacy">
                    <mat-label>{{ 'forms.setup-personal.birthdayPrivacyLabel' | translate }}</mat-label>
                    <img src="/assets/img/misc/birthday-visibility.png" />
                    <mat-radio-button name="Public" [value]="false">{{
                        'forms.setup-personal.birthdayPublicOption' | translate
                    }}</mat-radio-button>
                    <mat-radio-button name="Private" [value]="true">{{
                        'forms.setup-personal.birthdayPrivateOption' | translate
                    }}</mat-radio-button>
                    <mat-error *ngIf="birthdayPrivacy.invalid">{{ birthdayPrivacy.errors | formErrors }}</mat-error>
                </div>
            </mat-radio-group>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'forms.personalInformation.phoneMobile' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="(416) 405-4039"
                    [textMask]="phoneNumberMask"
                    #phoneMobile="ngModel"
                    name="phoneMobile"
                    [(ngModel)]="employee.phoneMobile"
                />
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'forms.personalInformation.phoneHome' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="(416) 405-4039"
                    [textMask]="phoneNumberMask"
                    #phoneHome="ngModel"
                    name="phoneHome"
                    [(ngModel)]="employee.phoneHome"
                />
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12" *permission="editSinPermission">
                <mat-label>{{ 'forms.personalInformation.sinssn' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    pattern="[0-9]{9,9}"
                    placeholder="123456789"
                    #sin="ngModel"
                    name="sin"
                    [required]="employee.isSinRequired"
                    [(ngModel)]="employee.sin"
                />
                <mat-error *ngIf="sin.invalid">{{ sin.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'forms.personalInformation.language' | translate }}</mat-label>
                <input name="language" matInput class="read-only" disabled [(ngModel)]="employee.language" />
            </mat-form-field>
        </div>

        <div class="app-grid">
            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.personalInformation.dietaryRestrictions' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.personalInformation.egAllergies' | translate }}"
                    #dietaryRestrictions="ngModel"
                    name="dietaryRestrictions"
                    [(ngModel)]="employee.dietaryRestrictions"
                />
            </mat-form-field>
        </div>
    </ng-container>
</form>
