import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LeaveOfAbsenceTypes } from '@app/models/employee/leave-of-absence-types.model';
import { LeaveOfAbsenceTypesDisplayOptions } from '@app/types/translatables/leave-of-absence-types-display.option';
import { BaseForm } from '@forms/base.form';
import { LeaveOfAbsence } from '@models/employee/leave-of-absence.model';
import moment, { Moment } from 'moment';

@Component({
    selector: 'app-leave-of-absence-information-form',
    templateUrl: './leave-of-absence-information.form.html',
})
export class LeaveOfAbsenceInformationForm extends BaseForm implements OnInit {
    @Input() leaveOfAbsence = new LeaveOfAbsence();
    @Input() leaveOfAbsenceTypes: LeaveOfAbsenceTypes[] = [];
    @Input() mode = 'create';
    @Output() leaveOfAbsenceStartDateChange = new EventEmitter<boolean>();
    @Input() employeeActiveOnPayroll = false;

    leaveOfAbsenceTypesDisplayOptions = LeaveOfAbsenceTypesDisplayOptions;
    minDateForEndDate = moment();

    leaveOfAbsenceStarted = false;
    disableStartDate = false;
    disableLeaveType = false;

    ngOnInit(): void {
        this.minDateForEndDate = this.leaveOfAbsence.start ? moment(this.leaveOfAbsence.start).add(1, 'day') : moment();
        this.leaveOfAbsenceStarted = moment(this.leaveOfAbsence.start).isSameOrBefore(moment());

        const disableInput = this.shouldDisableInput();
        this.disableStartDate = disableInput;
        this.disableLeaveType = disableInput;
    }

    onChangeDate(date: Moment): void {
        this.leaveOfAbsence.start = date;
        this.minDateForEndDate = this.leaveOfAbsence.start ? moment(this.leaveOfAbsence.start).add(1, 'day') : moment();
        this.leaveOfAbsenceStartDateChange.emit(true);
    }

    shouldDisableInput(): boolean {
        if (this.mode === 'create') {
            return false;
        }

        return this.employeeActiveOnPayroll || this.leaveOfAbsenceStarted;
    }
}
