import { Model } from '../core/base.model';
import Video from '@models/videos/video.model';

export class OnboardingOption extends Model {
    protected static _resource = 'companyManagement/companies/:company/companyHireConfigurations';
    protected static _version = 'v2';
    protected static _serializeAttributes = ['defaultWelcomeMessage', 'defaultVideoId'];

    get defaultWelcomeMessage() {
        return this._attributes['defaultWelcomeMessage'] || null;
    }

    set defaultWelcomeMessage(val: any) {
        this._attributes['defaultWelcomeMessage'] = val;
    }

    get defaultOnboardingVideo(): Video | null {
        return this.hasOne(Video, 'defaultOnboardingVideo');
    }

    set defaultOnboardingVideo(video: Video | null) {
        this._attributes['defaultVideoId'] = video?.id ?? '';
        this.setOne('defaultOnboardingVideo', video, 'defaultVideoId');
    }
}
