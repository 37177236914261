import { Component, Input, ViewChild } from '@angular/core';
import { Note } from '@models/employee/note.model';
import { AuthService } from '@app/services/auth.service';
import { BaseForm } from '@forms/base.form';

@Component({
    selector: 'app-form-record',
    templateUrl: './record.template.html',
})
export class RecordForm extends BaseForm {
    @Input() record: Note = new Note();
    @ViewChild('form', { static: true }) form;

    constructor(private auth: AuthService) {
        super();
        this.record.authorId = this.auth.user.id;
    }

    setRecord(record: Note): void {
        this.record = record;
    }

    reset(): void {
        this.record = new Note({ authorId: this.auth.user.id });
        this.form.reset();
        this.form.submitted = false;
    }
}
