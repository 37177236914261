<form #form="ngForm" autocomplete="off">
    <ng-container *ngIf="taxCredit">
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>Name</mat-label>
                <input type="text" name="name" required #name="ngModel" [(ngModel)]="taxCredit.name" matInput />
                <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>Jurisdiction</mat-label>
                <mat-select
                    name="jurisdiction"
                    required
                    #jurisdiction="ngModel"
                    [(ngModel)]="taxCredit.jurisdiction"
                    matInput
                >
                    <mat-option value="Canada">Canada</mat-option>
                    <mat-option value="AB">AB</mat-option>
                    <mat-option value="BC">BC</mat-option>
                    <mat-option value="MB">MB</mat-option>
                    <mat-option value="NB">NB</mat-option>
                    <mat-option value="NL">NL</mat-option>
                    <mat-option value="NT">NT</mat-option>
                    <mat-option value="NS">NS</mat-option>
                    <mat-option value="NU">NU</mat-option>
                    <mat-option value="ON">ON</mat-option>
                    <mat-option value="PE">PE</mat-option>
                    <mat-option value="SK">SK</mat-option>
                    <mat-option value="YT">YT</mat-option>
                </mat-select>
                <mat-error *ngIf="jurisdiction.invalid">{{ jurisdiction.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>Amount</mat-label>
                <input type="number" required name="amount" #amount="ngModel" [(ngModel)]="taxCredit.amount" matInput />
                <mat-error *ngIf="amount.invalid">{{ amount.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>Effective Date</mat-label>
                <input
                    required
                    name="date"
                    #date="ngModel"
                    [(ngModel)]="taxCredit.date"
                    [matDatepicker]="effectiveAtPicker"
                    matInput
                />
                <mat-datepicker-toggle matSuffix [for]="effectiveAtPicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #effectiveAtPicker></mat-datepicker>
                <mat-error *ngIf="date.invalid">{{ date.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
