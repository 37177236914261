<form #form="ngForm" autocomplete="off" [class.loading]="isLoading">
    <ng-container *ngIf="!isLoading">
        <div class="app-grid">
            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.employmentInformation.hireDate' | translate }}</mat-label>
                <input
                    placeholder="{{ 'forms.employmentInformation.selectADate' | translate }}"
                    required
                    name="hiredAt"
                    #hiredAt="ngModel"
                    [ngModel]="employee.hiredAt"
                    (ngModelChange)="employee.hiredAt = $event"
                    [matDatepicker]="hiredAtPicker"
                    matInput
                />
                <mat-datepicker-toggle matSuffix [for]="hiredAtPicker">
                    <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #hiredAtPicker></mat-datepicker>
                <mat-error *ngIf="hiredAt.invalid">{{ hiredAt.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.employmentInformation.reportsTo' | translate }}</mat-label>
                <ui-employee-autocomplete
                    name="reportsTo"
                    #reportsTo="ngModel"
                    [allowNone]="true"
                    [ngModel]="employee.manager"
                    (ngModelChange)="$event ? (employee.reportsTo = $event.id) : (employee.reportsTo = null)"
                ></ui-employee-autocomplete>

                <mat-error *ngIf="reportsTo.invalid">{{ reportsTo.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12" *ngIf="departments">
                <mat-label appRemoveAriaOwns>{{ 'forms.employmentInformation.department' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'forms.employmentInformation.selectADepartment' | translate }}"
                    name="department"
                    #department="ngModel"
                    [(ngModel)]="employee.departmentId"
                >
                    <mat-option value="">{{ 'forms.employmentInformation.selectAnOption' | translate }}</mat-option>
                    <mat-option *ngFor="let option of departments" [value]="option.id">{{ option.name }} </mat-option>
                </mat-select>
                <mat-error *ngIf="department.invalid">{{ department.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12" *ngIf="jobs">
                <mat-label appRemoveAriaOwns>{{ 'forms.employmentInformation.position' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'forms.employmentInformation.selectAPosition' | translate }}"
                    name="position"
                    #position="ngModel"
                    [(ngModel)]="employee.jobId"
                >
                    <mat-option value="">{{ 'forms.employmentInformation.selectAnOption' | translate }}</mat-option>
                    <mat-option *ngFor="let option of jobs" [value]="option.id">{{ option.title }} </mat-option>
                </mat-select>
                <mat-error *ngIf="position.invalid">{{ position.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12" *ngIf="offices">
                <mat-label appRemoveAriaOwns>{{ 'forms.employmentInformation.officeName' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'forms.employmentInformation.selectAnOffice' | translate }}"
                    required
                    name="office_id"
                    #office_id="ngModel"
                    [(ngModel)]="employee.officeId"
                    [employeeOfficeErrors]="employee"
                >
                    <mat-option *ngIf="!employee.isPayrollSyncEnabled" value="">
                        {{ 'forms.employmentInformation.selectAnOption' | translate }}
                    </mat-option>
                    <mat-option
                        *ngFor="let option of offices"
                        [value]="option.id"
                        [disabled]="
                            !hasQcPayrollFeature &&
                            employee.isPayrollSyncEnabled &&
                            option.isOfficeUnsupportedForPayroll
                        "
                        >{{ option.name }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="office_id.invalid">{{ office_id.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label appRemoveAriaOwns>{{ 'forms.employmentInformation.employmentType' | translate }}</mat-label>
                <mat-select
                    placeholder="{{ 'forms.employmentInformation.selectAType' | translate }}"
                    name="employmentType"
                    #employmentType="ngModel"
                    [(ngModel)]="employee.employmentType"
                >
                    <mat-option value="">{{ 'forms.employmentInformation.selectAnOption' | translate }}</mat-option>
                    <mat-option *ngFor="let option of employmentTypes" [value]="option"
                        >{{ option | appTranslateOptions: employmentTypesDisplay | capitalize }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="employmentType.invalid">{{ employmentType.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.employmentInformation.stockOptions' | translate }}</mat-label>
                <textarea
                    type="text"
                    #stockOptions="ngModel"
                    name="stockOptions"
                    [(ngModel)]="employee.stockOptions"
                    matInput
                ></textarea>
                <mat-error *ngIf="stockOptions.invalid">{{ stockOptions.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-12">
                <mat-label>{{ 'forms.employmentInformation.additionalTerms' | translate }}</mat-label>
                <textarea
                    type="text"
                    #additionalTerms="ngModel"
                    name="additionalTerms"
                    [maxLength]="500"
                    [(ngModel)]="employee.additionalTerms"
                    matInput
                ></textarea>
                <mat-error *ngIf="additionalTerms.invalid">{{ additionalTerms.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
