<!-- Apply task modal -->
<app-deprecated-modal-form
    #applyTasksModal
    [form]="applyTasksForm"
    [label]="'Apply'"
    (save)="onApplyTaskTemplates(applyTasksForm.getSelectedTaskTemplates())"
    headerText="{{ 'forms.employees-leaveOfAbsence.tasks' | appTranslate }}"
>
    <app-deprecated-apply-tasks
        #applyTasksForm
        [employee]="employee"
        [autoMatchedTaskTemplates]="autoMatchedTaskTemplates"
        [otherTaskTemplates]="otherTaskTemplates"
    ></app-deprecated-apply-tasks>
</app-deprecated-modal-form>

<form class="ui form" #form="ngForm">
    <!-- Tasks -->
    <div class="section">
        <div class="row">
            <h3 class="ui header">
                {{ 'forms.employees-leaveOfAbsence.tasks' | appTranslate }}
                <div class="sub header">{{ 'forms.employees-leaveOfAbsence.selectTasks' | appTranslate }}</div>
            </h3>
            <ui-button (click)="onAddTask()" type="outline">
                <mat-icon svgIcon="plus"></mat-icon>{{ 'add' | appTranslate }}
            </ui-button>
        </div>

        <!-- List -->
        <div *ngIf="selectedTaskTemplates.length" class="item-list">
            <app-common-label-item
                *ngFor="let taskTemplate of selectedTaskTemplates"
                (remove)="onRemoveTask(taskTemplate)"
                [label]="taskTemplate.name"
            >
            </app-common-label-item>
        </div>
    </div>
</form>
