<form #form="ngForm">
    <div class="app-grid">
        <mat-form-field class="columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.journey.itemType' | appTranslate }}</mat-label>
            <mat-select required name="field" [(ngModel)]="journey.field" #field="ngModel">
                <mat-option value=""> </mat-option>
                <mat-option value="position">{{ 'forms.journey.position' | appTranslate }} </mat-option>
                <mat-option value="salary">{{ 'forms.journey.salary' | appTranslate }} </mat-option>
                <mat-option value="department">{{ 'forms.journey.department' | appTranslate }} </mat-option>
                <mat-option value="stock_mat-options">{{ 'forms.journey.stockOptions' | appTranslate }} </mat-option>
                <mat-option value="compensation_type"
                    >{{ 'forms.journey.compensationType' | appTranslate }}
                </mat-option>
                <mat-option value="employment_type">{{ 'forms.journey.employmentType' | appTranslate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="field.invalid">{{ field.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-12">
            <mat-label>{{ 'forms.journey.oldValue' | appTranslate }}</mat-label>
            <input
                type="text"
                required
                name="old_value"
                [(ngModel)]="journey.oldValue"
                #oldValue="ngModel"
                placeholder="{{ 'forms.journey.oldValueExample' | appTranslate }}"
                matInput
            />
            <mat-error *ngIf="oldValue.invalid">{{ oldValue.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-12">
            <mat-label>{{ 'forms.journey.newValue' | appTranslate }}</mat-label>
            <input
                type="text"
                required
                name="new_value"
                [(ngModel)]="journey.newValue"
                #newValue="ngModel"
                placeholder="{{ 'forms.journey.newValueExample' | appTranslate }}"
                matInput
            />
            <mat-error *ngIf="newValue.invalid">{{ newValue.errors | formErrors }}</mat-error>
        </mat-form-field>

        <mat-form-field class="columns-12">
            <mat-label>{{ 'forms.journey.effectiveDate' | appTranslate }}</mat-label>
            <input
                required
                name="effective_date"
                [(ngModel)]="journey.effectiveDate"
                #effectiveDate="ngModel"
                placeholder="{{ 'forms.journey.selectEffectiveDateDate' | appTranslate }}"
                [matDatepicker]="effectiveDatePicker"
                matInput
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #effectiveDatePicker></mat-datepicker>
            <mat-error *ngIf="effectiveDate.invalid">{{ effectiveDate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
