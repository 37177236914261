import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { makePercentageMask } from '@app/functions';
import { resetCursorPosition } from '@app/functions/reset-cursor-position';
import { stripNonNumeric } from '@app/functions/strip-non-numeric';
import { PendingPayrollStatus } from '@app/interfaces/pending-payroll-status.interface';
import { Currency } from '@app/models/common/currency.model';
import { CompensationService } from '@app/modules/employees/services';
import { TimeOffIntegratedCompensationVacationPayService } from '@app/modules/payroll/services';
import {
    TimeOffIntegratedCompensationVacationPay,
    TimeOffIntegrationCompensationDetails,
} from '@app/modules/payroll/services/compensation-vacation-pay.service';
import { AuthService, CurrencyService, NotifyService, PayrollService } from '@app/services';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { Salary } from '@models/employee/salary.model';
import { parse } from 'date-fns';
import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { CompensationMode } from '../compensation-mode.enum';

@Component({
    selector: 'app-primary-compensation-form',
    templateUrl: './primary-compensation.form.template.html',
    styleUrls: ['./primary-compensation.form.styles.scss'],
})
export class PrimaryCompensationForm extends BaseForm implements OnInit, OnChanges {
    @ViewChild('form') form;
    @Input() employee: Employee;
    @Input() salary: Salary;
    @Input() isEditMode = false;
    @Input() lastPaidRecurringPayrollEndDate: Date = null;
    @Output() disabledSaveButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() populateCustomESAPercentage: EventEmitter<number> = new EventEmitter<number>();
    @Output() displayVacationPayInCompensationHistoryTable: EventEmitter<boolean> = new EventEmitter<boolean>();
    disableSaveButton = false;
    pendingPayrollEndDate: Date | null = null;
    isPayrollAdmin = false;
    currencies: Currency[] = [];

    currencyMask = createNumberMask({
        prefix: '$',
        includeThousandsSeparator: true,
        allowDecimal: true,
        guide: true,
        keepCharPositions: false,
    });

    percentageMask = makePercentageMask();

    showVacationPayPercentage = false;
    compensationMode: string;
    disableCompensationName = false;
    timeOffIntegratedVacationPayPercentage: TimeOffIntegratedCompensationVacationPay = null;
    vacationPayHasLoaded = false;
    compensationIntegratedWithTimeOff: boolean = null;
    employeeCurrentSalaries: Salary[] = null;
    allEmployeePrimaryCompensations: Salary[] = null;
    timeOffIntegratedCompensationsAreSalaried = false;
    timeOffIntegrationCompensationDetails: TimeOffIntegrationCompensationDetails;
    couldCompensationsBeIntegratedWithTimeOff = false;
    lastRecurringPayrollEndDate: Date | null;
    employeeCurrentPrimaryCompensations: Salary[];
    disableFormDueToRegularPayEndDate = false;
    mustMarkCompensationAsPrimary = false;

    get showVacationPay(): boolean {
        return this.employee.is(this.auth.employee) || this.auth.isAdmin();
    }

    constructor(
        private auth: AuthService,
        private payrollService: PayrollService,
        private notify: NotifyService,
        private currencyService: CurrencyService,
        private compensationService: CompensationService,
        private timeOffIntegratedCompensationVacationPayService: TimeOffIntegratedCompensationVacationPayService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.isPayrollAdmin = this.auth.can('accessPayroll');
        this.salary.hoursPerWeek = this.isEditMode ? this.salary.hoursPerWeek : 40;
        if (this.isEditMode) {
            this.compensationMode = this.salary?.isPrimary ? CompensationMode.new : CompensationMode.additional;
        }
        try {
            this.employeeCurrentPrimaryCompensations = await this.getEmployeePrimaryCompensations();
            this.currencies = await this.currencyService.getAllCurrencies();
            this.pendingPayrollEndDate = this.employee.isPayrollSyncEnabled
                ? await this.checkPendingPayrollStatus()
                : null;
            this.lastRecurringPayrollEndDate = this.employee.isPayrollSyncEnabled
                ? await this.payrollService.getLastRecurringPayrollEndDate(this.auth.company.id)
                : null;
            this.couldCompensationsBeIntegratedWithTimeOff =
                await this.timeOffIntegratedCompensationVacationPayService.isCompensationAbleToHaveTimeOffIntegratedCompensations(
                    this.employee
                );
            this.timeOffIntegrationCompensationDetails = this.couldCompensationsBeIntegratedWithTimeOff
                ? await this.getTimeOffIntegratedVacationPay(this.employee.id)
                : {
                      isOnVacationPayPolicy: false,
                      isPayrollTimeOffIntegrationOn: false,
                      timeOffTypeId: null,
                      timeOffIntegratedVacationPay: null,
                  };
            this.employeeCurrentSalaries = this.couldCompensationsBeIntegratedWithTimeOff
                ? await this.getEmployeeCurrentSalaries()
                : null;
            this.allEmployeePrimaryCompensations = await this.getAllEmployeePrimaryCompensations();
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
        this.timeOffIntegratedVacationPayPercentage =
            this.timeOffIntegrationCompensationDetails.isOnVacationPayPolicy &&
            this.timeOffIntegrationCompensationDetails.timeOffTypeId
                ? this.timeOffIntegrationCompensationDetails.timeOffIntegratedVacationPay
                : null;
        if (!this.couldCompensationsBeIntegratedWithTimeOff || !this.timeOffIntegratedVacationPayPercentage) {
            this.setCompensationTableEmitter();
        }
        if (this.timeOffIntegratedVacationPayPercentage) {
            this.displayVacationPayInCompensationHistoryTable.emit(false);
        }
        this.timeOffIntegratedCompensationsAreSalaried =
            this.timeOffIntegratedVacationPayPercentage && this.employeeCurrentSalaries.length > 1
                ? this.determineIfTimeOffIntegratedCompensationsAreSalaried()
                : false;
        this.vacationPayHasLoaded = true;
    }

    setCompensationTableEmitter(): void {
        this.displayVacationPayInCompensationHistoryTable.emit(true);
    }

    /*
        This method gets called during ngOnInit(), at which point no optional parameter is passed. 
        It will only get called if there is a timeOffIntegratedVacationPayPercentage and the employee's
        current salaries has a lengh of greater than one. As per our current payroll-time off integration logic, hourly employees
        are still allowed to modify vacation percentage if they are inegrated with time off if they only have hourly compensations. 
        This is because we have not yet implemented modifying their vacation percentages based on accruals set up in time off, only salaried employees 
        can be modified. However, if the employee has multiple compensations and is integrated with payroll and time off,
        but even one of them is a salary, they can no longer modify any of their hourly compensation vacation pay. Therefore, this optional
        parameter is included within this method. If an integrated employee has one yearly compensation and one hourly compensation,
        and is currently editing their yearly compensation and switched it to hourly, they should be able to add a vacation pay
        percentage before saving, as they will have two hourly compensations. Therefore, we're re-checking the flag that determines
        whether or not the vacation pay percentage box displays by looping over our current salary with the values currently 
        being edited in the form.
    */
    determineIfTimeOffIntegratedCompensationsAreSalaried(
        modifySalaryWithCurrentChangesBeingUpdated?: boolean
    ): boolean {
        const employeeCurrentSalaries: Salary[] = modifySalaryWithCurrentChangesBeingUpdated
            ? this.updateCurrentSalaryData(this.employeeCurrentSalaries)
            : this.employeeCurrentSalaries;
        const employeeHasYearlySalary = employeeCurrentSalaries.some(
            (currentSalary: Salary) => currentSalary.frequency === 'year'
        );
        return employeeHasYearlySalary;
    }

    updateCurrentSalaryData(employeeSalaries: Salary[]): Salary[] {
        this.timeOffIntegratedCompensationsAreSalaried = false;
        const currentSalaryIndex = employeeSalaries.findIndex((salary: Salary) => salary.id === this.salary.id);
        employeeSalaries[currentSalaryIndex] = this.salary;
        return employeeSalaries;
    }

    async getEmployeeCurrentSalaries(): Promise<Salary[]> {
        const [allEmployeePrimaryCompensations] = await Salary.param('company', this.auth.company.id)
            .param('employee', this.employee.id)
            .where('current', true)
            .get();
        return allEmployeePrimaryCompensations;
    }

    async getAllEmployeePrimaryCompensations(): Promise<Salary[]> {
        const [allEmployeePrimaryCompensations] = await Salary.param('company', this.auth.company.id)
            .param('employee', this.employee.id)
            .where('isPrimary', true)
            .get();
        return allEmployeePrimaryCompensations;
    }

    async getEmployeePrimaryCompensations(): Promise<Salary[]> {
        const [employeeCurrentPrimaryCompensations] = await Salary.param('company', this.auth.company.id)
            .param('employee', this.employee.id)
            .where('current', true)
            .where('isPrimary', true)
            .get();
        return employeeCurrentPrimaryCompensations;
    }

    ngOnChanges(): void {
        if (this.isEditMode && this.salary) {
            this.showVacationPayPercentage = this.shouldVacationPayBeDisplayed(this.isEditMode, this.salary);
        }
        if (this.employee.isPayrollSyncEnabled) {
            this.employee.currency = 'CAD';
        }
    }

    async getTimeOffIntegratedVacationPay(employeeId: number): Promise<TimeOffIntegrationCompensationDetails> {
        return await this.timeOffIntegratedCompensationVacationPayService.getTimeOffIntegratedCompensationVacationPay(
            employeeId
        );
    }

    setCompensationAsPrimary(): void {
        this.mustMarkCompensationAsPrimary = true;
        this.compensationMode = CompensationMode.new;
        this.setCompensationName();
    }

    async handlePaymentFrequencyChanged(): Promise<void> {
        this.disableFormDueToRegularPayEndDate = false;
        if (this.salary.frequency === 'year') {
            this.compensationMode = CompensationMode.new;
            this.setCompensationAsPrimary();
        }
        if (this.salary.frequency === 'hour') {
            await this.determineFormEditableState();
        }
        if (!this.timeOffIntegratedVacationPayPercentage) {
            return;
        }
        this.timeOffIntegratedCompensationsAreSalaried =
            this.determineIfTimeOffIntegratedCompensationsAreSalaried(true);
    }

    determineIfThereIsAPrimaryCompensationInTheFuture(employeeCompensations: Salary[]): boolean {
        const futurePrimaryCompensationsemployeeCompensations = employeeCompensations.filter((compensation: Salary) => {
            const isEffectiveAtInTheFuture = moment(compensation?.effectiveAt).isAfter(new Date());
            if (isEffectiveAtInTheFuture) {
                return true;
            }
        });
        return Boolean(futurePrimaryCompensationsemployeeCompensations.length);
    }

    async determineFormEditableState(): Promise<void> {
        const currentSalaryIsAPrimaryComp = this.allEmployeePrimaryCompensations
            .filter((comp) => comp.isPrimary)
            .some((compensation) => compensation.id === this.salary.id);
        if (
            this.allEmployeePrimaryCompensations.length === 0 ||
            (this.allEmployeePrimaryCompensations.length === 1 && currentSalaryIsAPrimaryComp)
        ) {
            this.setCompensationAsPrimary();
            return;
        }
        const isThereAPrimaryCompensationInTheFuture = this.determineIfThereIsAPrimaryCompensationInTheFuture(
            this.allEmployeePrimaryCompensations
        );
        if (isThereAPrimaryCompensationInTheFuture) {
            return;
        }
        if (!this.employee.isPayrollSyncEnabled) {
            if (this.employeeCurrentPrimaryCompensations && this.employeeCurrentPrimaryCompensations.length > 0) {
                this.mustMarkCompensationAsPrimary = false;
                return;
            }
            this.setCompensationAsPrimary();
            return;
        }
        const lastRecurringPayrollEndDate = await this.payrollService.getLastRecurringPayrollEndDate(
            this.auth.company.id
        );
        const primaryCompEndDates: Date[] | null[] = this.employeeCurrentPrimaryCompensations.map(
            (primaryComp: Salary) => primaryComp.endsAt
        );
        const employeeHasPrimaryWithNoEndDate: boolean = this.employeeCurrentPrimaryCompensations.some(
            (compensation) => {
                return compensation.endsAt === null;
            }
        );
        const employeeHasPrimaryCompEndingAfterLastPayroll: boolean = primaryCompEndDates.some((endDate) => {
            const parsedEndDate = parse(endDate).getTime();
            const parsedLastRecurringPayrollEndDate = parse(lastRecurringPayrollEndDate).getTime();

            return parsedEndDate > parsedLastRecurringPayrollEndDate;
        });

        if (
            lastRecurringPayrollEndDate === null ||
            employeeHasPrimaryCompEndingAfterLastPayroll ||
            employeeHasPrimaryWithNoEndDate
        ) {
            this.mustMarkCompensationAsPrimary = false;
            return;
        }
        this.setCompensationAsPrimary();
    }

    setCompensationName(): void {
        if (this.compensationMode === CompensationMode.new || this.mustMarkCompensationAsPrimary) {
            this.salary.name = 'Regular Pay';
            this.disableCompensationName = true;
            return;
        }
        this.disableCompensationName = false;
        this.salary.name = '';
    }

    shouldVacationPayBeDisplayed(isEditMode: boolean, salary: Salary): boolean {
        return isEditMode && salary.vacationPayPercentage > 0;
    }

    stripNonNumeric(val: string | number): number | null {
        if (!val) {
            return null;
        }

        return stripNonNumeric(val);
    }

    onRemoveVacationPay(): void {
        this.salary.vacationPayPercentage = null;
        this.showVacationPayPercentage = false;
    }

    resetCursorPosition(): void {
        resetCursorPosition();
    }

    /**
     * We disable this form if there is a pending payroll
     * and the new salary start date is before the pending payroll's end date
     * If this logic changes, this function should be modified
     */
    setFormEnabledStatus(): void {
        this.disableSaveButton = this.compensationService.enablePayrollForm(
            this.employee,
            this.salary,
            this.pendingPayrollEndDate
        );
        this.disabledSaveButtonEmitter.emit(this.disableSaveButton);
    }

    populateCustomESAmountPercentage(customESAPercentage: string): void {
        const cleanedCustomESAPercentage = parseInt(customESAPercentage.replace('%', ''), 10);
        this.populateCustomESAPercentage.emit(cleanedCustomESAPercentage);
    }

    private async checkPendingPayrollStatus(): Promise<Date> {
        const payrollPendingStatusEndDate: Date = await this.payrollService
            .getPendingPayrollStatus()
            .then(({ endDate }: PendingPayrollStatus) => {
                return endDate;
            });
        return payrollPendingStatusEndDate;
    }
}
