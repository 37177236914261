import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EmploymentTypes } from '@app/constants/employment-types';
import { FeatureFlag } from '@app/enums';
import { NotifyService } from '@app/services';
import { AuthService } from '@app/services/auth.service';
import { FeatureService } from '@app/services/feature.service';
import { EmploymentTypeDisplayOptions } from '@app/types/translatables/employment-type-display.options';
import { BaseForm } from '@forms/base.form';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';

@Component({
    selector: 'app-form-employment-information',
    templateUrl: './employment-information.template.html',
})
export class EmploymentInformationForm extends BaseForm {
    @Input() employee!: Employee;

    isLoading = true;
    numberControl: FormControl<number | null>;

    employmentTypes: string[] = EmploymentTypes;

    offices: Office[] = [];
    departments: Department[] = [];
    jobs: Job[] = [];

    employmentTypesDisplay = EmploymentTypeDisplayOptions;
    hasQcPayrollFeature = false;

    constructor(
        private auth: AuthService,
        private notify: NotifyService,
        private features: FeatureService
    ) {
        super();

        this.getDropdownData();
    }

    async ngOnInit(): Promise<void> {
        this.hasQcPayrollFeature = await this.features.has(FeatureFlag.qcPayroll);
    }

    private getDropdownData(): void {
        const promises = [
            Office.param('company', this.auth.company.id).limit(0).all(),
            Department.param('company', this.auth.company.id).orderBy('name', 'ASC').limit(0).all(),
            Job.param('company', this.auth.company.id).orderBy('title', 'ASC').limit(0).all(),
        ];

        Promise.all(promises)
            .then((res) => {
                // Destructure 2 deep
                [this.offices, this.departments, this.jobs] = [res[0][0], res[1][0], res[2][0]];

                this.isLoading = false;
            })
            .catch(() => {
                this.notify.error('forms.employmentInformation.dropdownDataError');
                this.isLoading = false;
            });
    }
}
