import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { KYCRevalidationWarningDirective } from '@app/directives/field-revalidation-warning/kyc-kyb-field-revalidation.directive';
import { AppCommonModule } from '@app/modules/common/common.module';
import { PlatformModule } from '@app/platform.module';
import { VideosModule } from '@videos/videos.module';
import { CompanyFormsModule } from '../company/company-forms.module';
import { EmployeeForms } from './components';
import { CrossDateValidatorDirective } from './components/leave-of-absence/information/date-cross-validation.directive';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        AppCommonModule,
        PlatformModule,
        CompanyFormsModule,
        RouterModule,
        VideosModule,
        KYCRevalidationWarningDirective,
    ],
    declarations: [...EmployeeForms, CrossDateValidatorDirective],
    exports: [...EmployeeForms, CrossDateValidatorDirective],
})
export class EmployeeFormsModule {}
