<form #form="ngForm">
    <ng-container *ngIf="reminder">
        <!-- Name/details -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>Name</mat-label>
                <input matInput type="text" required [(ngModel)]="reminder.name" #name="ngModel" name="name" />
                <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>Details</mat-label>
                <input matInput type="text" [(ngModel)]="reminder.body" name="body" />
            </mat-form-field>
        </div>

        <!-- Send To -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>Send To</mat-label>
                <mat-select
                    required
                    [ngModel]="remindToType"
                    (ngModelChange)="setRemindToType($event)"
                    #remindTo="ngModel"
                    name="remindTo"
                    placeholder="Select an option"
                >
                    <mat-option value="manager">Manager</mat-option>
                    <mat-option value="employee">New Hire</mat-option>
                    <mat-option value="administrator">Administrator</mat-option>
                    <mat-option value="colleague">Colleague</mat-option>
                </mat-select>
                <mat-error *ngIf="remindTo.invalid">{{ remindTo.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="remindToType === 'colleague'" class="columns-6 small-columns-12">
                <mat-label>Select Colleague</mat-label>
                <ui-employee-autocomplete
                    [ngModel]="employee"
                    (ngModelChange)="setColleague($event)"
                    required
                    name="colleague"
                    #colleague="ngModel"
                ></ui-employee-autocomplete>
                <mat-error *ngIf="colleague.invalid">{{ colleague.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <!-- When to Send -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>When To Send</mat-label>
                <mat-select
                    [(ngModel)]="remindBefore"
                    (ngModelChange)="setRemindBefore(remindBefore)"
                    name="remindBefore"
                    required
                    #remindBeforeInput="ngModel"
                >
                    <mat-option value="on">On Hire Date</mat-option>
                    <mat-option value="before">Before Hire Date</mat-option>
                    <mat-option value="after">After Hire Date</mat-option>
                </mat-select>
                <mat-error *ngIf="remindBeforeInput">{{ remindBeforeInput.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="remindBefore !== 'on'" class="columns-6 small-columns-12">
                <mat-label>Days {{ remindBefore === 'before' ? 'Before First Day' : 'After First Day' }}</mat-label>
                <input
                    matInput
                    type="number"
                    required
                    min="0"
                    [(ngModel)]="reminder.delayDays"
                    #days="ngModel"
                    name="delayDays"
                />
                <mat-error *ngIf="days.invalid">{{ days.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
