import { Component, Input, OnInit } from '@angular/core';
import { LeaveOfAbsenceTypes } from '@app/models/employee/leave-of-absence-types.model';
import { TaskTemplate } from '@app/models/tasks/task-template.model';
import { LeaveOfAbsenceTypesDisplayOptions } from '@app/types/translatables/leave-of-absence-types-display.option';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { LeaveOfAbsence } from '@models/employee/leave-of-absence.model';

type LeaveOfAbsenceTypesMeta = {
    [key: number]: string;
};

@Component({
    selector: 'app-leave-of-absence-review',
    templateUrl: './leave-of-absence-review.form.html',
    styleUrls: ['./leave-of-absence-review.form.scss'],
})
export class LeaveOfAbsenceReviewForm extends BaseForm implements OnInit {
    @Input() employee = new Employee();
    @Input() leaveOfAbsence = new LeaveOfAbsence();
    @Input() selectedTaskTemplates: TaskTemplate[] = [];

    leaveOfAbsenceTypes: LeaveOfAbsenceTypes[] = [];

    leaveOfAbsenceDisplayMeta: LeaveOfAbsenceTypesMeta = [];

    leaveOfAbsenceTypesDisplayOptions = LeaveOfAbsenceTypesDisplayOptions;

    async ngOnInit(): Promise<void> {
        const [leaveOfAbsenceTypes] = await LeaveOfAbsenceTypes.all();

        this.leaveOfAbsenceDisplayMeta = Object.assign(
            {},
            ...leaveOfAbsenceTypes.map((leaveOfAbsenceType: LeaveOfAbsenceTypes) => {
                return { [leaveOfAbsenceType.id]: leaveOfAbsenceType.name };
            })
        );
    }
}
