<form class="ui form" #form="ngForm" autocomplete="off">
    <ui-banner *ngIf="shouldShowBackDatedBanner" variant="warning">
        <p
            [innerHTML]="
                'forms.employees-terminate.friendlyReminderLastDayOfWork'
                    | translate: { date: lastPaidRecurringPayrollEndDate | appDate }
            "
        ></p>
    </ui-banner>
    <!-- Termination Information -->
    <app-segment-header title="{{ 'forms.employees-terminate.terminationDetails' | translate }}"></app-segment-header>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.employees-terminate.employee' | translate }}</label>
            {{ employee.fullName }}
        </div>

        <div class="field">
            <label>{{ 'forms.employees-terminate.lastDayOfWork' | translate }}</label>
            {{ employee.lastDayOfWorkOn | appDate }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.employees-terminate.terminationType' | translate }}</label>
            {{ employee.terminationReason }} ({{ employee.terminationReasonCode }})
        </div>

        <div class="field">
            <label>{{ 'forms.employees-terminate.reason' | translate }}</label>
            {{ employee.terminationComments }}
        </div>
    </div>
    <div class="three fields" *ngIf="hasManagedBenefitPolicy">
        <div class="field">
            <label>{{ 'forms.employees-terminate.employeesBenefitsEndDate' | translate }}</label>
            {{ (benefitExtensionDate ? benefitExtensionDate : employee.lastDayOfWorkOn) | appDate }}
        </div>
    </div>

    <!-- Offboarding Reminders -->
    <ng-container *ngIf="offboardingReminders.length">
        <app-segment-header
            title="{{ 'forms.employees-terminate.offboardingReminders' | translate }}"
        ></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let reminder of offboardingReminders"
                [removable]="false"
                [label]="reminder.name ?? ''"
            ></app-common-label-item>
        </div>
    </ng-container>

    <div style="padding: 1em"></div>

    <!--Instructions-->
    <app-segment-header title="{{ 'forms.employees-terminate.terminationProcedure' | translate }}"></app-segment-header>

    <h2 class="ui header">
        <span class="ui blue label counter"></span>&nbsp;&nbsp;{{ 'forms.employees-terminate.access' | translate }}
    </h2>

    <!--termination in past-->
    <p
        *ngIf="terminationInPast === true"
        style="padding-left: 3em"
        data-cy="past-or-present-termination"
        [innerHTML]="
            'forms.employees-terminate.employeeAccessWillBeRevokedImmediately'
                | translate: { firstName: employee.firstName }
        "
    ></p>

    <!--termination in future-->
    <p
        *ngIf="terminationInPast === false"
        style="padding-left: 3em"
        data-cy="future-termination"
        [innerHTML]="
            'forms.employees-terminate.employeeWillRetainAccess'
                | translate: { firstName: employee.firstName, date: lastDayOfAccessToHumi | appDate }
        "
    ></p>

    <h2 class="ui header">
        <span class="ui blue label counter"></span>&nbsp;&nbsp;{{ 'forms.employees-terminate.benefits' | translate }}
    </h2>

    <ng-container *ngIf="showBenefitExtensionOption && isNotProtectedLeave && isNotQuitLeave">
        <div class="padding-left-5 extension-of-benefits">
            <p>
                <strong>{{ 'forms.employees-terminate.extensionOfBenefits' | translate }}</strong>
            </p>

            <p *ngIf="!benefitExtensionDate">{{ 'na' | translate }}</p>

            <p
                *ngIf="benefitExtensionDate"
                [innerHTML]="
                    'forms.employees-terminate.extensionHasBeenRequest'
                        | translate: { extensionDate: (benefitExtensionDate | appDate) }
                "
            ></p>

            <p
                *ngIf="benefitExtensionDate"
                [innerHTML]="'forms.employees-terminate.uponSubmissionOfTermination' | translate"
            ></p>
        </div>
    </ng-container>

    <ng-container *ngIf="showBenefitExtensionOption && !isNotProtectedLeave && !isNotQuitLeave">
        <div class="padding-left-5 nothing-for-benefits">
            <p *ngIf="!benefitExtensionDate">{{ 'na' | translate }}</p>
        </div>
    </ng-container>

    <ng-container *ngIf="!showBenefitExtensionOption">
        <div class="padding-left-5 standard-benefits">
            <p [innerHTML]="'forms.employees-terminate.ifHasGroupBenefits' | translate"></p>
        </div>
    </ng-container>

    <ng-container *ngIf="!employee.isPayrollSyncEnabled">
        <h2 class="ui header">
            <span class="ui blue label counter"></span>&nbsp;&nbsp;{{ 'forms.employees-terminate.payroll' | translate }}
        </h2>
        <p
            style="padding-left: 3em"
            [innerHTML]="'forms.employees-terminate.notOnHumiPayroll' | translate: { firstName: employee.firstName }"
        ></p>
    </ng-container>

    <!-- Hello. I'm following the conventions of this template. -->
    <!-- If you have been asked to add a 5th step, please explain to the design team that we need to redo this template. -->
    <!-- It's not obvious to designers that there can be many steps because not every step always appears. With four steps it already looks a bit messy. -->
    <!-- Ideally, a rework of this template will use proper HTML semantics and roles. It should handle many items.  -->
    <ng-container *ngIf="employeeIsOnTimeTracking">
        <h2 class="ui header">
            <span class="ui blue label counter"></span>&nbsp;&nbsp;{{
                'forms.employees-terminate.timeTracking' | translate
            }}
        </h2>
        <p
            style="padding-left: 3em"
            [innerHTML]="
                'forms.employees-terminate.employeeOnTimeTracking' | translate: { firstName: employee.firstName }
            "
        ></p>
    </ng-container>
</form>
