<form class="ui form" #form="ngForm">
    <!-- Personal Info -->
    <h2>Personal</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">First Name</label>
            <div>{{ employee.firstName }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Last Name</label>
            <div>{{ employee.lastName }}</div>
        </div>
    </div>

    <div class="three fields">
        <div class="field">
            <label class="font-medium">Email</label>
            <div>{{ account.email }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Date of Birth</label>
            <div>{{ employee.bornOn | appDate: 'yyyy-MM-dd' }}</div>
        </div>
    </div>

    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">SIN</label>
            <div>{{ employee.sin }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Address -->
    <h2 class="margin-top-3">Address</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">Street Address</label>
            <div>{{ address.addressLine1 }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Country</label>
            <div>{{ address.country }}</div>
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">Postal Code</label>
            <div>{{ address.postalCode }}</div>
        </div>
        <div class="field">
            <label class="font-medium">City</label>
            <div>{{ address.city }}</div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">Province</label>
            <div>{{ address.province }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Job -->
    <h2 class="margin-top-3">Job</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">Employment Type</label>
            <div>{{ employee.employmentType }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Hired On</label>
            <div>{{ employee.hiredAt | appDate: 'yyyy-MM-dd' }}</div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">Office</label>
            <div>{{ employee?.office?.name }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Termination -->
    <h2 class="margin-top-3">Termination</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">Termination Date</label>
            <div>{{ terminatedOn | appDate: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Termination Reason</label>
            <div>{{ terminationReasonLabel }}</div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">Termination Comments</label>
            <div>{{ employee.terminationComments }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Payment -->
    <h2 class="margin-top-3">Payment</h2>
    <div class="three fields">
        <div *ngIf="compensationTypes.hasOwnProperty(this.salary.frequency)" class="field">
            <label class="font-medium">Compensation Type</label>
            <div>{{ salary.frequencyLabel }}</div>
        </div>
        <div *ngIf="!!salary.rate" class="field">
            <label class="font-medium">{{ salary.frequencyLabel }}</label>
            <div>{{ salary.rate | currency }}</div>
        </div>
    </div>
    <div class="three fields">
        <div *ngIf="!!salary.effectiveAt" class="field">
            <label class="font-medium">Salary Effective Date</label>
            <div>{{ salary.effectiveAt | appDate: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="field">
            <label class="font-medium">Currency</label>
            <div>{{ employee.currency }}</div>
        </div>
    </div>
    <div class="three fields">
        <div *ngIf="!!salary.hoursPerWeek" class="field">
            <label class="font-medium">Hours per week</label>
            <div>{{ salary.hoursPerWeek }}</div>
        </div>
    </div>

    <!-- Optional -->
    <ng-container *ngIf="canDoPayroll">
        <mat-divider></mat-divider>
        <h2 class="margin-top-3">Payroll</h2>

        <div class="three fields">
            <div class="field">
                <label>Sync to Payroll?</label>
                <div>{{ employee.isPayrollSyncEnabled ? 'Yes' : 'No' }}</div>
            </div>
        </div>
    </ng-container>
</form>
