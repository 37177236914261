<form class="ui form" #form="ngForm" autocomplete="off" [class.isLoading]="isLoading">
    <p
        class="onboarding-settings-text"
        [innerHTML]="
            {
                key: 'forms.applicant-tracker.review.youCanChangeYourDefaultOptionsIn',
                params: { link: onboardingSettingsURL },
            } | appTranslate
        "
    ></p>
    <!-- Collect Onboarding Questions? -->
    <div class="three fields">
        <div class="field onboarding-questions">
            <label>{{ 'forms.applicant-tracker.review.sendHiringQuestionnaire' | appTranslate }}</label>
            <h3
                *ngIf="!hasOnboardingQuestions"
                [innerHTML]="
                    'forms.applicant-tracker.review.noOnboardingQuestionsExistToAddOnboardingQuestionsVisitThe'
                        | appTranslate
                "
            ></h3>
            <app-radio-toggle
                *ngIf="hasOnboardingQuestions"
                name="collectHiringQuestions"
                [disabled]="!hasOnboardingQuestions"
                [(ngModel)]="employee.collectHiringQuestions"
            >
            </app-radio-toggle>
        </div>
        <div class="field" *ngIf="!selfServeFeatureFlag">
            <label>{{ 'forms.applicant-tracker.review.sendCustomWelcomeMessage' | appTranslate }}</label>
            <app-radio-toggle
                name="sendCustomWelcomeMessage"
                [(ngModel)]="customWelcomeMessage"
                (ngModelChange)="setEmployeeWelcomeMessage($event)"
            ></app-radio-toggle>
        </div>
    </div>
    <div *ngIf="customWelcomeMessage" class="two fields">
        <div class="ten wide field">
            <label>{{ 'forms.applicant-tracker.review.customWelcomeMessage' | appTranslate }}</label>
            <mat-form-field class="width-full">
                <ui-rich-text
                    name="customWelcomeMessageInput"
                    #customWelcomeMessageInput="ngModel"
                    [(ngModel)]="employee.welcomeMessage"
                ></ui-rich-text>
            </mat-form-field>
        </div>
    </div>

    <ng-container *ngIf="onboardingVideo">
        <app-segment-header
            title="forms.applicant-tracker.review.onboardingVideo"
            class="margin-bottom-1"
        ></app-segment-header>
        <div class="onboarding-video-section margin-bottom-2">
            <video-lookup [videoUrl]="defaultOnboardingVideo?.url" (metaChange)="handleOnboardingVideoChange($event)">
                <div class="info">
                    <mat-icon svgIcon="info"></mat-icon
                    >{{
                        'forms.applicant-tracker.review.thisVideoCannotBeChangedOrRemovedOnceTheOnboardinglinkHasBeenSen'
                            | appTranslate
                    }}
                </div>
            </video-lookup>
        </div>
    </ng-container>

    <!-- Account Information -->
    <app-segment-header title="forms.applicant-tracker.review.accountInformation"></app-segment-header>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.firstName' | appTranslate }}</label>
            {{ account.legalFirstName }}
        </div>

        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.lastName' | appTranslate }}</label>
            {{ account.legalLastName }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.email' | appTranslate }}</label>
            {{ account.email }}
        </div>

        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.officeName' | appTranslate }}</label>
            {{ employee.office?.name }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.hireDate' | appTranslate }}</label>
            {{ employee.hiredAt | appDate: 'yyyy-MM-dd' }}
        </div>

        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.additionalTerms' | appTranslate }}</label>
            {{ employee.additionalTerms }}
        </div>
    </div>

    <!-- Compensation -->
    <app-segment-header title="forms.applicant-tracker.review.compensation"></app-segment-header>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.compensationType' | appTranslate }}</label>
            {{
                (salary.frequency === 'year'
                    ? 'applicant-tracker.compensationTypes.yearly'
                    : 'applicant-tracker.compensationTypes.hours'
                ) | appTranslate
            }}
        </div>
        <div class="field">
            <label>{{
                (salary.frequency === 'year'
                    ? 'applicant-tracker.compensationTypes.salary'
                    : 'applicant-tracker.compensationTypes.rate'
                ) | appTranslate
            }}</label>
            {{ salary.rate }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.employmentType' | appTranslate }}</label>
            {{ employee.employmentType }}
        </div>

        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.hoursPerWeek' | appTranslate }}</label>
            {{ employee.hoursPerWeek }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.stockOptions' | appTranslate }}</label>
            {{ employee.stockOptions }}
        </div>
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.vacationPayPercentage' | appTranslate }}</label>
            {{ salary.vacationPayPercentage }}
        </div>
    </div>

    <!-- Employee's Role -->
    <app-segment-header title="forms.applicant-tracker.review.employeesRole"></app-segment-header>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.department' | appTranslate }}</label>
            {{ employee.department?.name }}
        </div>
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.reportsTo' | appTranslate }}</label>
            {{ employee.manager?.fullName || 'None' }}
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.applicant-tracker.review.position' | appTranslate }}</label>
            {{ employee.job?.title }}
        </div>
    </div>

    <ng-container *ngIf="employee.isPayrollSyncEnabled">
        <app-segment-header title="forms.applicant-tracker.review.humiPayroll"></app-segment-header>
        <div class="three fields">
            <div class="field">
                <label>{{ 'forms.applicant-tracker.review.automaticallySyncedWithPayroll' | appTranslate }}</label>
                <label class="no-margin-bottom sinRequired">{{
                    'forms.applicant-tracker.review.sinRequiredDuringOnboarding' | appTranslate
                }}</label>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!employee.isPayrollSyncEnabled && employee.isSinRequired">
        <app-segment-header title="forms.applicant-tracker.review.employeeSin"></app-segment-header>
        <div class="three fields">
            <div class="field">
                <label class="no-margin-bottom sinRequired">{{
                    'forms.applicant-tracker.review.required' | appTranslate
                }}</label>
            </div>
        </div>
    </ng-container>

    <!-- Documents -->
    <ng-container *ngIf="documentAssignments.length">
        <app-segment-header title="forms.applicant-tracker.review.documents"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let documentAssignment of documentAssignments"
                [removable]="false"
                [label]="documentAssignment.companyDocument.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Custom Fields -->
    <ng-container *ngIf="customFields.length">
        <app-segment-header title="forms.applicant-tracker.review.customFields"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let customField of customFields"
                [removable]="false"
                [label]="customField.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Time Off Policies -->
    <ng-container *ngIf="selectedTimeOffPolicies.length">
        <app-segment-header title="forms.applicant-tracker.onboarding.timeOffPolicies"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let policy of selectedTimeOffPolicies"
                [removable]="false"
                [label]="policy.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Time tracking -->
    <ng-container *ngIf="showTimeTracking">
        <app-segment-header title="time-tracking.name"></app-segment-header>
        <div class="field">
            <label>{{ 'time-tracking.hiring.enabled' | appTranslate }}</label>
            {{ (addToTimeTracking ? 'Yes' : 'No') | appTranslate }}
        </div>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let project of timeTrackingProjects"
                [removable]="false"
                [label]="project.name"
            ></app-common-label-item>
        </div>
        <div *ngIf="timeTrackingPayrollIntegrationOnForCompany && addToTimeTracking" class="field">
            <label>{{ 'time-tracking.hiring.transferHoursToPayroll' | appTranslate }}</label>
            {{ (addToTimeTrackingPayrollIntegration ? 'Yes' : 'No') | appTranslate }}
        </div>
    </ng-container>

    <!-- Roles -->
    <ng-container *ngIf="roles.length">
        <app-segment-header title="forms.applicant-tracker.review.roles"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let role of roles"
                [removable]="false"
                [label]="role.name | appTranslateOptions: roleDisplay"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Training Programs -->
    <ng-container *ngIf="trainingPrograms.length">
        <app-segment-header title="forms.applicant-tracker.review.trainingPrograms"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let trainingProgram of trainingPrograms"
                [removable]="false"
                [label]="trainingProgram.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Onboarding Reminders -->
    <ng-container *ngIf="onboardingReminders.length">
        <app-segment-header title="forms.applicant-tracker.review.onboardingReminders"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let reminder of onboardingReminders"
                [removable]="false"
                [label]="reminder.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Work Schedule -->
    <ng-container *ngIf="workSchedule">
        <app-segment-header title="forms.applicant-tracker.review.workSchedule"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                [removable]="false"
                [label]="workSchedule.name | appTranslateOptions: workScheduleDisplay"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Benefits -->
    <ng-container *ngIf="selectedBenefitLabel">
        <app-segment-header title="forms.applicant-tracker.review.benefits"></app-segment-header>
        <div class="item-list">
            <app-common-label-item [removable]="false" [label]="selectedBenefitLabel"></app-common-label-item>
        </div>
    </ng-container>
</form>
