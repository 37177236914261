import { Component, Input } from '@angular/core';
import { AuthService } from '@app/services/auth.service';
import { BaseForm } from '@forms/base.form';
import { Reminder } from '@models/employee/reminder.model';

@Component({
    selector: 'app-form-reminder',
    templateUrl: './reminder.template.html',
})
export class ReminderForm extends BaseForm {
    @Input() reminder: Reminder = new Reminder();
    minDate = new Date();

    constructor(private auth: AuthService) {
        super();
        this.reminder.creatorId = this.auth.employee.id;
    }

    reset(): void {
        super.reset();
        this.reminder.creatorId = this.auth.employee.id;
    }

    setReminder(reminder: Reminder): void {
        this.reminder = reminder;
    }
}
