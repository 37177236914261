<form #form="ngForm">
    <!-- Employment -->
    <h2 class="ui header">Employment</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>Employment Type</mat-label>
            <mat-select
                required
                name="employmentType"
                placholder="Select employment type"
                [(ngModel)]="employee.employmentType"
                #employmentType="ngModel"
            >
                <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">{{
                    employmentType | capitalize
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="employmentType.invalid">{{ employmentType.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Hire Date</mat-label>
            <input
                matInput
                placeholder="Select a date"
                required
                name="hiredAt"
                #hiredAt="ngModel"
                [(ngModel)]="employee.hiredAt"
                [matDatepicker]="hiredAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="hiredAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #hiredAtPicker></mat-datepicker>
            <mat-error *ngIf="hiredAt.invalid">{{ hiredAt.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Last Day of Work</mat-label>
            <input
                matInput
                placeholder="Select a date"
                required
                name="lastDayOfWork"
                #lastDayOfWork="ngModel"
                [(ngModel)]="employee.lastDayOfWorkOn"
                [matDatepicker]="lastDayOfWorkOnPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="lastDayOfWorkOnPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #lastDayOfWorkOnPicker></mat-datepicker>
            <mat-error *ngIf="lastDayOfWork.invalid">{{ lastDayOfWork.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>Office</mat-label>
            <mat-select name="office" required #office="ngModel" [(ngModel)]="employee.office">
                <mat-option *ngFor="let office of offices" [value]="office">{{ office.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="office.invalid">{{ office.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <!-- Termination -->
    <h2 class="ui header">Termination</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Termination Date</mat-label>
            <input
                matInput
                placeholder="Select a date"
                required
                name="terminatedAt"
                #terminatedDate="ngModel"
                [ngModel]="terminatedOn"
                (ngModelChange)="setTerminatedOn($event)"
                [matDatepicker]="terminatedAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="terminatedAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #terminatedAtPicker></mat-datepicker>

            <mat-error *ngIf="terminatedDate.invalid">{{ terminatedDate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>Termination Type</mat-label>
            <mat-select
                name="terminationReasonCode"
                required
                #terminationReasonCode="ngModel"
                [(ngModel)]="employee.terminationReasonCode"
            >
                <mat-option *ngFor="let tCode of terminationCodes | keyvalue" [value]="tCode.key">{{
                    tCode.value
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="terminationReasonCode.invalid">{{ terminationReasonCode.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Termination Reason</mat-label>
            <input
                matInput
                type="text"
                [(ngModel)]="employee.terminationComments"
                name="terminationComments"
                placeholder="Eg: New Opportunity, Not A Good Fit, Poor Performance"
                #terminationComments="ngModel"
            />
            <mat-error *ngIf="terminationComments.invalid">{{ terminationComments.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
