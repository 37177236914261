<form #form="ngForm" class="app-grid">
    <ng-container *ngIf="hireQuestion">
        <mat-form-field class="columns-12">
            <mat-label>Question</mat-label>
            <input
                matInput
                type="text"
                required
                #question="ngModel"
                [(ngModel)]="hireQuestion.question"
                name="question"
            />
            <mat-error *ngIf="question.invalid">{{ question.errors | formErrors }}</mat-error>
        </mat-form-field>
    </ng-container>
</form>
