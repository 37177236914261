<form #form="ngForm">
    <ng-container *ngIf="employee">
        <p>{{ description | appTranslate }}</p>
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.firstName' | translate }}</mat-label>
                <input
                    KYC-Revalidate
                    type="text"
                    placeholder="{{ 'account.firstNamePlaceHolder' | translate }}"
                    required
                    #firstName="ngModel"
                    name="firstName"
                    [(ngModel)]="employee.firstName"
                    matInput
                />
                <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
            </mat-form-field>
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.lastName' | translate }}</mat-label>
                <input
                    KYC-Revalidate
                    type="text"
                    placeholder="{{ 'account.lastNamePlaceHolder' | translate }}"
                    required
                    #lastName="ngModel"
                    name="lastName"
                    [(ngModel)]="employee.lastName"
                    matInput
                />
                <mat-error *ngIf="lastName.invalid">{{ lastName.errors | formErrors }}</mat-error>
            </mat-form-field>

            <div class="app-grid margin-bottom-3 name-pronunciation" *ngIf="shouldShowEmployeeNamePronunciation">
                <div class="columns-6">
                    <div class="flex col">
                        <mat-label class="margin-bottom-1">{{ 'account.namePronunciation' | translate }}</mat-label>
                        <app-audio-recorder
                            #audioRecorder
                            (recording)="isAudioRecording = $event"
                            *ngIf="shouldShowEmployeeNamePronunciation"
                            [(audioBlob)]="audioBlob"
                            [(supportedFormat)]="supportedAudioFormat"
                            (audioPlayed)="addPendoEventForAudioPlayed()"
                        ></app-audio-recorder>
                    </div>
                </div>
            </div>

            <!-- Genders -->
            <div class="app-grid">
                <add-genders
                    class="columns-6"
                    [initialGenders]="employee.genders"
                    (emitAllGenders)="setGenders($event)"
                ></add-genders>
            </div>

            <!-- Pronouns -->
            <div class="app-grid">
                <add-pronouns
                    class="columns-6"
                    [initialPronouns]="employee.pronouns"
                    (emitAllPronouns)="setPronouns($event)"
                ></add-pronouns>
            </div>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'account.phoneWork' | translate }}</mat-label>
                <input
                    type="text"
                    placeholder="416-405-4039"
                    #phoneWork="ngModel"
                    name="phoneWork"
                    [(ngModel)]="employee.phoneWork"
                    matInput
                />
                <mat-error *ngIf="phoneWork.invalid">{{ phoneWork.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
