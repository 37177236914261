import { Component, Input } from '@angular/core';
import { Employee } from '@app/models/employee/employee.model';
import { BaseForm } from '@forms/base.form';
@Component({
    selector: 'app-form-emergency-contacts',
    templateUrl: './emergency-contacts.form.html',
})
export class EmergencyContactsForm extends BaseForm {
    @Input() employee!: Employee;
}
