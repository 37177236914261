<form class="app-grid" #form="ngForm" autocomplete="off">
    <mat-form-field class="columns-6 small-columns-12">
        <mat-label>{{ 'account.country' | translate }}</mat-label>
        <ui-country-autocomplete
            placeholder="{{ 'account.pleasePick' | translate }}"
            name="countryName"
            #country="ngModel"
            [required]="true"
            [(ngModel)]="address.country"
        ></ui-country-autocomplete>
        <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
    </mat-form-field>
    <mat-form-field class="columns-6 small-columns-12">
        <mat-label>{{ 'account.provinceState' | translate }}</mat-label>
        <ui-state-autocomplete
            placeholder="{{ 'account.pleasePick' | translate }}"
            name="provinceName"
            #province="ngModel"
            [required]="true"
            [country]="address.country"
            [(ngModel)]="address.province"
        ></ui-state-autocomplete>
        <mat-error *ngIf="province.invalid">{{ province.errors | formErrors }}</mat-error>
    </mat-form-field>

    <mat-form-field class="columns-6 small-columns-12">
        <mat-label>{{ 'account.city' | translate }}</mat-label>
        <input
            type="text"
            placeholder="{{ 'account.cityPlaceHolder' | translate }}"
            required
            #city="ngModel"
            name="city"
            [(ngModel)]="address.city"
            matInput
        />
        <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
    </mat-form-field>

    <ng-container *ngIf="address.country === 'Canada'">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'account.postalCode' | translate }}</mat-label>
            <input
                type="text"
                placeholder="{{ 'account.postalCodePlaceHolder' | translate }}"
                required
                #postalCode="ngModel"
                name="postalCode"
                [textMask]="postalCodeMask"
                [(ngModel)]="address.postalCode"
                matInput
            />
            <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="address.country !== 'Canada'">
        <mat-form-field class="columns-6 small-columns-12">
            <mat-label>{{ 'account.zipPostalCode' | translate }}</mat-label>
            <input
                type="text"
                placeholder="{{ 'account.zipCodePlaceHolder' | translate }}"
                required
                #postalCode="ngModel"
                name="postalCode"
                [(ngModel)]="address.postalCode"
                matInput
            />
            <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
        </mat-form-field>
    </ng-container>

    <mat-form-field class="columns-6 small-columns-12">
        <mat-label>{{ 'account.streetAddress' | appTranslate }}</mat-label>
        <input
            type="text"
            placeholder="{{ 'account.streetPlaceHolder' | translate }}"
            required
            #addressLine1="ngModel"
            name="addressLine1"
            [(ngModel)]="address.addressLine1"
            matInput
        />
        <mat-error *ngIf="addressLine1.invalid">{{ addressLine1.errors | formErrors }}</mat-error>
    </mat-form-field>
    <mat-form-field class="columns-6 small-columns-12">
        <mat-label>{{ 'account.unitSuite' | translate }}</mat-label>
        <input
            type="text"
            placeholder="{{ 'account.unitSuitePlaceHolder' | translate }}"
            #addressLine2="ngModel"
            name="addressLine2"
            [(ngModel)]="address.addressLine2"
            matInput
        />
        <mat-error *ngIf="addressLine2.invalid">{{ addressLine2.errors | formErrors }}</mat-error>
    </mat-form-field>
</form>
