<form #form="ngForm">
    <!-- Basic -->
    <h2 class="ui header">Basics</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>First Name</mat-label>
            <input
                matInput
                type="text"
                required
                placeholder="Eg: Alex"
                name="firstName"
                [(ngModel)]="employee.firstName"
                #firstName="ngModel"
            />
            <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Last Name</mat-label>
            <input
                matInput
                type="text"
                name="lastName"
                placeholder="Eg: Smith"
                [(ngModel)]="employee.lastName"
                #lastName="ngModel"
                required
            />
            <mat-error *ngIf="lastName.invalid">{{ lastName.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Email</mat-label>
            <input
                matInput
                type="text"
                name="email"
                placeholder="Eg: alex@company.com"
                #email="ngModel"
                [(ngModel)]="account.email"
                required
                emailValidator
            />
            <mat-error *ngIf="email.invalid">{{ email.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Date of Birth</mat-label>
            <input
                matInput
                placeholder="Select a date..."
                required
                #dateOfBirth="ngModel"
                name="bornAt"
                [(ngModel)]="employee.bornOn"
                [matDatepicker]="dateOfBirthPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dateOfBirthPicker></mat-datepicker>

            <mat-error *ngIf="dateOfBirth.invalid">{{ dateOfBirth.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>SIN</mat-label>
            <input
                matInput
                type="text"
                placeholder="123456789"
                #sin="ngModel"
                maxlength="9"
                pattern=".{9,9}"
                required
                name="sin"
                [(ngModel)]="employee.sin"
            />
            <mat-error *ngIf="sin.invalid">{{ sin.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <!-- Address -->
    <h2 class="ui header">Address</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Street Address</mat-label>
            <input
                matInput
                type="text"
                placeholder="325 Front Street W"
                required
                #addressLine1="ngModel"
                name="addressLine1"
                [(ngModel)]="address.addressLine1"
            />
            <mat-error *ngIf="addressLine1.invalid">{{ addressLine1.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Country</mat-label>
            <ui-country-autocomplete
                placeholder="Please pick"
                name="countryName"
                #country="ngModel"
                required
                [(ngModel)]="address.country"
            ></ui-country-autocomplete>

            <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>Province</mat-label>
            <ui-state-autocomplete
                placeholder="Please pick"
                name="provinceName"
                #province="ngModel"
                required
                [country]="address.country"
                [(ngModel)]="address.province"
            ></ui-state-autocomplete>

            <mat-error *ngIf="province.invalid">{{ province.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>City</mat-label>
            <input
                matInput
                type="text"
                placeholder="Toronto"
                required
                #city="ngModel"
                name="city"
                [(ngModel)]="address.city"
            />
            <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <ng-container *ngIf="address.country === 'Canada'">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>Postal Code</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="A1B 2C3"
                    required
                    #postalCode="ngModel"
                    name="postalCode"
                    [textMask]="postalCodeMask"
                    [(ngModel)]="address.postalCode"
                />
                <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="address.country !== 'Canada'">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>Zip/Postal Code</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="A1B 2C3 or 55123"
                    required
                    #postalCode="ngModel"
                    name="postalCode"
                    [(ngModel)]="address.postalCode"
                />
                <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>
</form>
