import { Component, Input, ViewChild } from '@angular/core';

import { LegacyTaxCredit } from '@app/models/employee/payroll/legacy-tax-credit.model';
import { BaseForm } from '@forms/base.form';

/**
 * @deprecated use [TaxCreditForm](../../../../employees/views/employee/job-and-pay/tax-credit/tax-credit-form/tax-credit-form.component.ts) instead
 */
@Component({
    selector: 'app-deprecated-form-tax-credit',
    templateUrl: './deprecated-tax-credit.template.html',
})
export class DeprecatedTaxCreditForm extends BaseForm {
    @ViewChild('form') form;
    @Input() taxCredit: LegacyTaxCredit = new LegacyTaxCredit();

    reset(): void {
        this.taxCredit = new LegacyTaxCredit();
        this.form.reset();
        this.form.submitted = false;
    }

    setTaxCredit(taxCredit: LegacyTaxCredit) {
        this.taxCredit = taxCredit;
    }
}
