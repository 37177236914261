import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TerminationCode, TerminationCodes } from '@app/constants/termination-codes';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import moment from 'moment';

// Number of days an employee can be terminated for us to send the conversion email
const OUT_OF_BOUND_PERIOD_IN_DAYS = 90;
const PROTECTED_LEAVE_TERMINATION_CODES = ['F00', 'P00', 'N00', 'Z00'];
const QUIT_LEAVE_TERMINATION_CODES = ['E00', 'E02', 'E03', 'E04', 'E05', 'E06', 'E09', 'E10', 'E11'];

@Component({
    selector: 'app-employees-form-terminate-information',
    templateUrl: './information.form.html',
})
export class TerminateInformationForm extends BaseForm {
    @Input() employee: Employee;
    @Input() showBenefitExtensionOption: boolean;
    @Output() outOfLastDayOfWorkOnBoundsChange = new EventEmitter<boolean>();
    @Input() benefitExtensionDate: Date | null = null;
    @Output() benefitExtensionDateChange = new EventEmitter<Date>();
    @Input() lastDayOfWorkOn: Date | null = null;
    @Output() lastDayOfWorkOnChange = new EventEmitter<Date>();

    terminationCodes = TerminationCodes;
    hasExtendedBenefits = false;
    outOfLastDayOfWorkOnBounds = false;
    isNotQuitLeave = false;
    isNotProtectedLeave = false;
    outOfBoundsPeriodInDays = OUT_OF_BOUND_PERIOD_IN_DAYS;
    protectedLeaveCodes = PROTECTED_LEAVE_TERMINATION_CODES;
    quitLeaveCodes = QUIT_LEAVE_TERMINATION_CODES;

    // Order by ascending property value
    valueAscOrder = (a: KeyValue<TerminationCode, string>, b: KeyValue<TerminationCode, string>): number => {
        return a.value.localeCompare(b.value);
    };

    onTerminationTypeChange(): void {
        this.isNotQuitLeave = !this.quitLeaveCodes.includes(this.employee.terminationReasonCode);

        this.isNotProtectedLeave = !this.protectedLeaveCodes.includes(this.employee.terminationReasonCode);

        if (!this.isNotProtectedLeave || !this.isNotQuitLeave) {
            this.setBenefitExtensionDate(null);
        }
    }

    onLastDayOfWorkOnChanged(): void {
        this.outOfLastDayOfWorkOnBounds =
            moment().diff(this.employee.lastDayOfWorkOn, 'days') >= OUT_OF_BOUND_PERIOD_IN_DAYS;
        this.outOfLastDayOfWorkOnBoundsChange.emit(this.outOfLastDayOfWorkOnBounds);
        this.lastDayOfWorkOn = moment(this.employee.lastDayOfWorkOn).toDate();
        this.lastDayOfWorkOnChange.emit(this.lastDayOfWorkOn);
    }

    setBenefitExtensionDate(date: Date | null): void {
        this.benefitExtensionDate = date;
        this.benefitExtensionDateChange.emit(this.benefitExtensionDate);
    }

    toggleHasBenefitsExtension(): void {
        this.hasExtendedBenefits = !this.hasExtendedBenefits;
        if (!this.hasExtendedBenefits) {
            this.setBenefitExtensionDate(null);
        }
    }
}
